import React, { useRef, useEffect, useContext } from "react";
import { ValidFilterContext } from "./ValidFilterContext";

const FilterCheckbox = (props) => {
    const checkboxRef = useRef();
    const validFilters = useContext(ValidFilterContext);

    let name;
    if (typeof props.node === "string" || props.node instanceof String) {
        name = props.node;
    } else {
        name = props.node.name;
    }

    // If there is a list of valid filters, and this checkbox is not in that list,
    // and it's not already checked, disable it.
    const disabled = Object.keys(validFilters).length && !validFilters[name] && !checkboxRef.current?.checked;

    useEffect(() => {
        // If it is in the current state check it on re-renders
        if (props.currentValues?.includes(name)) {
            checkboxRef.current.checked = true;
            checkboxRef.current.indeterminate = false;
        } else {
            checkboxRef.current.checked = false;
            checkboxRef.current.indeterminate = false;
        }
    }, [props.currentValues, name]);

    const checkboxID = `checkbox-${props.groupName}-${name.replace(/\s/g, "")}`;
    return (
        <label className={disabled ? "text-c_michael_gray" : ""} htmlFor={checkboxID}>
            <input
                ref={checkboxRef}
                type="checkbox"
                id={checkboxID}
                name={name}
                onChange={(e) => props.handler(e)}
                data-value={name}
                disabled={disabled}
            />
            {name}
        </label>
    );
};

export default FilterCheckbox;
