import React, { useEffect, useState, useRef, useCallback } from "react";
import classnames from "classnames/bind";
import { useStaticQuery, graphql } from "gatsby";
import { useI18n } from "@v4/utils/i18nContext";
import * as listingStyles from "./eventListing.module.css";
import FilterGroup from "../../filters/FilterGroup";
import EventCard from "./EventCard";
import { eventListingTranslations } from "../../../translations/eventListing";
import scrollWithHeaderOffset from "@v4/utils/scrollWithHeaderOffset";
import { withDefault, NumberParam } from "use-query-params";
import { withQueryParamProvider } from "@v4/utils/withQueryParamProvider";
import { PipeArrayDelimiter, getTermSubTerms, getFilterTaxonomyTree, useQueryParams } from "@v4/utils/filterUtils";
import Link from "@v4/gatsby-theme-talend/src/components/Link";
import { getLangPrefixedPath } from "@v4/utils/pathPrefix";

const cx = classnames.bind(listingStyles);

// Course Event fragment is used in static query below.
export const fragment = graphql`
    fragment Event on ContentfulEvent {
        id
        contentful_id
        node_locale
        name
        title
        summary
        updatedAt
        #-- Filters --#
        eventType {
            name
            contentful_id
        }
        category {
            name
            contentful_id
        }
        location {
            name
            contentful_id
        }
        language {
            name
            contentful_id
        }
        eventLanguage
        #-- Entry values --#
        startDate
        endDate
        startTime
        url
        pageLink {
            ...PageLink
        }
    }
`;

// A filterable listing of all courses in the current language.
const EventListing = ({ title, tierOneItems, itemsPerPage }) => {
    const { i18n } = useI18n();
    const data = useStaticQuery(graphql`
        query EventsQuery {
            events: allContentfulEvent {
                nodes {
                    __typename
                    ... on Node {
                        ...Event
                    }
                }
            }
            eventTaxonomies: allContentfulTaxonomy(filter: { name: { eq: "Events" } }) {
                nodes {
                    name
                    node_locale
                    terms {
                        ...TermFields
                        ...SubTermFields
                    }
                }
            }
            # Hardcoded the contentful ID in here for the "On-demand webinar" term under Resources taxonomy
            resourceTerm: allContentfulTerm(filter: { contentful_id: { eq: "6UKhyW90JJa0Fc8tNQllYN" } }) {
                nodes {
                    node_locale
                    name
                }
            }
        }
    `);

    // Filter state.
    const [allParams, setAllParams] = useQueryParams({
        eventTypes: withDefault(PipeArrayDelimiter, []),
        locations: withDefault(PipeArrayDelimiter, []),
        categories: withDefault(PipeArrayDelimiter, []),
        languages: withDefault(PipeArrayDelimiter, []),
        page: withDefault(NumberParam, 1),
    });

    // The list of events that match the current filters.
    const [events, setEvents] = useState([]);

    // The list of currently visible events depending on pagination.
    const [eventsList, setEventsList] = useState([]);

    // Pagination state.
    const [hasMore, setHasMore] = useState(false);

    // Flags to determine whether we should filter.
    const shouldFilterByEventType = allParams.eventTypes?.length;
    const shouldFilterByLocation = allParams.locations?.length;
    const shouldFilterByCategory = allParams.categories?.length;
    const shouldFilterByLanguage = allParams.languages?.length;

    // Get relevant taxonomy terms (Events in the current language).
    const filterTaxonomyTree = getFilterTaxonomyTree(data.eventTaxonomies, i18n.curr.langCode, "Events");

    // Availability/EventType/Location/Category/Languages term list.
    const eventTypes = getTermSubTerms(filterTaxonomyTree?.[0].name, filterTaxonomyTree);
    const locations = getTermSubTerms(filterTaxonomyTree?.[1].name, filterTaxonomyTree);
    const categories = getTermSubTerms(filterTaxonomyTree?.[2].name, filterTaxonomyTree);
    const languages = getTermSubTerms(filterTaxonomyTree?.[3].name, filterTaxonomyTree);

    // Map categories to gradients (classnames) -- Get the category terms in english with corresponding contentful ID,
    // then map the current language category to the english category name using contentful ID, finally
    // grab the gradient name using the english category name.
    // We will use this function in the returnEventCard function
    // to pass the gradient classname string as a prop to the EventCard component.
    const englishTaxonomyTree = getFilterTaxonomyTree(data.eventTaxonomies, "en", "Events");
    const categoriesWithIds = getTermSubTerms(filterTaxonomyTree?.[2].name, filterTaxonomyTree, false);
    const englishCategoriesWithIds = getTermSubTerms(englishTaxonomyTree?.[2].name, englishTaxonomyTree, false);

    const categoryToGradientMap = (category) => {
        const categoryId = categoriesWithIds.find((categoryWithId) => categoryWithId.name === category)?.contentful_id;
        return {
            "Talend live": "bgGradientEvents1",
            "Talend demo": "bgGradientEvents1",
            Webinar: "bgGradientEvents2",
            "Talend event": "bgGradientEvents2",
            Conference: "bgGradientEvents3",
            Expo: "bgGradientEvents3",
            Forum: "bgGradientEvents3",
        }[englishCategoriesWithIds.find((category) => category.contentful_id === categoryId)?.name];
    };

    // We'll also keep a list of events in the language that's selected in the lp language field.
    // The intent is to translate as much of the event content as is needed into the selected language.
    const translatedEvents = data.events.nodes.filter(
        (event) => event.node_locale === i18n.get("langCode").from("langName", event.eventLanguage)
    );

    // Get the event in its listed language using the current page language version via contentful_id.
    const getTranslatedEvent = (event) =>
        translatedEvents.find((translatedEvent) => event.contentful_id === translatedEvent.contentful_id);

    const handleFiltering = useCallback(
        (data) => {
            let filteredEvents = data;

            if (shouldFilterByEventType) {
                filteredEvents = filteredEvents.filter(
                    (event) =>
                        event.eventType && event.eventType.some((term) => allParams?.eventTypes.includes(term.name))
                );
            }

            if (shouldFilterByLocation) {
                filteredEvents = filteredEvents.filter(
                    (event) => event.location && event.location.some((term) => allParams?.locations.includes(term.name))
                );
            }

            if (shouldFilterByCategory) {
                filteredEvents = filteredEvents.filter(
                    (event) =>
                        event.category && event.category.some((term) => allParams?.categories.includes(term.name))
                );
            }

            if (shouldFilterByLanguage) {
                filteredEvents = filteredEvents.filter(
                    (event) => event.language && event.language.some((term) => allParams?.languages.includes(term.name))
                );
            }
            setEvents(filteredEvents);
            setEventsList([...filteredEvents.slice(0, allParams.page * itemsPerPage)]);
        },
        [
            shouldFilterByCategory,
            shouldFilterByEventType,
            shouldFilterByLanguage,
            shouldFilterByLocation,
            itemsPerPage,
            allParams,
        ]
    );

    useEffect(() => {
        // Grab the ids from tier one (pinned to top) items so that we can remove them from
        // the remaining list of events.
        const tierOneIds = tierOneItems
            .sort((a, b) => new Date(b.startDate) - new Date(a.startDate))
            .map((item) => item.contentful_id);

        // Tier 2 events are all remaining events that aren't tier 1.
        const allTierTwoEvents = data.events.nodes
            .filter((event) => event.node_locale === i18n.curr.langCode)
            .filter((event) => !tierOneIds.includes(event.contentful_id))
            // filter out events with no start date at all
            .filter((event) => event.startDate !== null)
            .sort((a, b) => new Date(a.startDate) - new Date(b.startDate));

        const allEvents = tierOneItems
            .concat(allTierTwoEvents)
            // Removing all Live (availability) events that are in the past.
            .filter((event) => !(new Date(event.startDate).getTime() < Date.now()));
        handleFiltering(allEvents);
    }, [data.events.nodes, tierOneItems, i18n.curr.langCode, handleFiltering]);

    // Check if there are more events to show.
    useEffect(() => {
        const isMore = eventsList.length < events.length;
        setHasMore(isMore);
    }, [eventsList, events]);

    // Filter config to pass to FilterGroup component below.
    const filters = filterTaxonomyTree && [
        {
            title: filterTaxonomyTree[0].name,
            allTerms: eventTypes,
            currentValues: allParams?.eventTypes,
            setter: (checkedBoxes) => {
                setAllParams({ ...allParams, eventTypes: checkedBoxes });
            },
            open: allParams?.eventTypes?.length > 0,
            name: "eventTypes",
            filterType: "Checkbox",
        },
        {
            title: filterTaxonomyTree[1].name,
            allTerms: locations,
            currentValues: allParams?.locations,
            setter: (checkedBoxes) => {
                setAllParams({ ...allParams, locations: checkedBoxes });
            },
            open: allParams?.locations?.length > 0,
            name: "locations",
            filterType: "Checkbox",
        },
        {
            title: filterTaxonomyTree[2].name,
            allTerms: categories,
            currentValues: allParams?.categories,
            setter: (checkedBoxes) => {
                setAllParams({ ...allParams, categories: checkedBoxes });
            },
            open: allParams?.categories?.length > 0,
            name: "categories",
            filterType: "Checkbox",
        },
        {
            title: filterTaxonomyTree[3].name,
            allTerms: languages,
            currentValues: allParams?.languages,
            setter: (checkedBoxes) => {
                setAllParams({ ...allParams, languages: checkedBoxes });
            },
            open: allParams?.languages?.length > 0,
            name: "languages",
            filterType: "Checkbox",
        },
    ];

    const resetFilters = () => {
        setAllParams({
            eventTypes: [],
            locations: [],
            categories: [],
            languages: [],
            page: 1,
        });
    };

    // For upcoming events, the cta text needs to be "Register"
    // For on-demand/past events, the cta text needs to be "Watch now".
    const getCtaText = (startDate, langName) => {
        const today = Date.now();
        const eventDate = new Date(startDate).getTime();
        // Undefined some times fallback to en language
        const langCode = i18n.get("langCode").from("langName", langName) ?? "en";

        if (today > eventDate) {
            return eventListingTranslations[langCode].watch;
        }

        return eventListingTranslations[langCode].register;
    };

    const NoResults = () => {
        return (
            <div className={cx("NoResultsMessage")}>
                <h3 className={cx("subTitle")}>{eventListingTranslations[i18n.curr.langCode].noResultsMessage}</h3>
            </div>
        );
    };

    // Create ref for "return to top" feature.
    const topRef = useRef(null);
    const scrollToTop = () => scrollWithHeaderOffset(topRef.current);

    // Handle "View more" button click
    const handleLoadMore = () => {
        if (hasMore) {
            const currentLength = eventsList.length;
            const isMore = currentLength < events.length;
            const nextResults = isMore ? events.slice(currentLength, currentLength + itemsPerPage) : [];
            setEventsList([...eventsList, ...nextResults]);
            setAllParams({ ...allParams, page: allParams.page + 1 });
        }
    };

    // This function will return the EventCard component
    // But first we need to do some checks, etc.
    const returnEventCard = (event, index) => {
        // Get a boolean to check if an event is "on-demand" and the start date is in the past.
        // If there is no start date, this will also evaluate to true.

        // create the strings which we will later pass to the EventCard props
        let availability = null;
        let location = null;

        // Show date (or date range)
        availability = event.startDate
            ? i18n.getLocalisedDate(event.startDate, event.endDate, event.eventLanguage)
            : "";
        // show time if available
        availability += event.startTime ? ` | ${event.startTime}` : "";
        // Show location
        location = getTranslatedEvent(event)?.location[0].name;

        // Now return the EventCard component with the necessary props
        return (
            <EventCard
                key={`event-${index}`}
                gradientClassName={categoryToGradientMap(event.category[0].name)}
                category={getTranslatedEvent(event)?.category[0].name}
                availability={availability}
                location={location}
                cardTitle={getTranslatedEvent(event)?.title}
                cardDesc={getTranslatedEvent(event)?.summary}
                ctaText={getCtaText(event.startDate, event.eventLanguage)}
                ctaUrl={getTranslatedEvent(event)?.url}
                ctaPageLink={getTranslatedEvent(event)?.pageLink}
                langCode={i18n.get("langCode").from("langName", event.eventLanguage)}
            />
        );
    };

    const resourcePageLinkLabel = {
        en: ["Take a look at our library of on-demand webinars"],
        "en-GB": ["Take a look at our library of on-demand webinars"],
        fr: ["Parcourez la liste de tous nos webinars disponibles en replay"],
        de: ["Werfen Sie einen Blick in unsere Bibliothek mit On-Demand-Webinaren"],
        it: ["Dai un'occhiata ai nostri webinar on-demand"],
        ja: ["オンデマンドウェビナーライブラリーをご覧ください"],
    };

    // The code below will build the url that points to the "on-demand" section of the Resource Center
    // 1. For this we need to get the localised term for the "On demand" in the Content types filter in the Resource Center
    const localisedTerm = data.resourceTerm.nodes.filter((term) => term.node_locale === i18n.curr.langCode)[0]?.name;
    // 2. Create the querystring to append to the pageurl below
    const queryString = localisedTerm ? `?type=${localisedTerm}` : "";
    // 3. Build the url to the resource page with "On demand webinars" filtered.
    const resourcePageUrl = `${getLangPrefixedPath(`/resources/`, i18n.curr.langCode)}${queryString}`;

    const EventListingWrapper = ({ events }) => {
        if (!events.length) return <NoResults />;
        return (
            <>
                <div className={cx("eventResults")}>{events.map((event, index) => returnEventCard(event, index))}</div>
                <div className={cx(`controlButtons`)}>
                    {hasMore && (
                        <button className={cx(`loadMoreButton`, `btn`, `btnTertiary`)} onClick={handleLoadMore}>
                            {eventListingTranslations[i18n.curr.langCode].viewMore}
                        </button>
                    )}
                    {allParams.page > 1 && (
                        <button className={cx(`backToTop`, `btn`, `btnTertiary`)} onClick={scrollToTop}>
                            {eventListingTranslations[i18n.curr.langCode].toTop}
                        </button>
                    )}
                </div>
            </>
        );
    };

    return (
        <div ref={topRef}>
            <div className={cx(`eventsFilterHeader`)}>
                {title}
                <Link to={resourcePageUrl} className={cx(`btn`, `btnLink`, `resourcePageLink`)}>
                    {resourcePageLinkLabel[i18n.curr.langCode]} <span className={cx("linkArrow")}>{`\u00bb`}</span>
                </Link>
            </div>
            <div className={cx(`listingWrapper`)}>
                <div className={cx(`listingFilters`)}>
                    {filters && <FilterGroup filters={filters} resetFunction={resetFilters} />}
                </div>
                <div className={cx(`listingResults`)}>
                    <EventListingWrapper events={eventsList} />
                </div>
            </div>
        </div>
    );
};

export default withQueryParamProvider(EventListing);
