import React from "react";
import { graphql } from "gatsby";
import { useI18n } from "@v4/utils/i18nContext";
import Image from "@v4/gatsby-theme-talend/src/components/Image/Image";
import CTA from "@v4/talend/src/components/cta/Cta";
import Link from "@v4/gatsby-theme-talend/src/components/Link";
import { commonTranslations } from "@v4/gatsby-theme-talend/src/translations/common";
import { normalizePath } from "@v4/utils/normalizePathOrUrl";
import { truncateOnBoundary } from "@v4/utils/truncate";
import { getPrefixedPathByPageType } from "@v4/utils/pathPrefix";

import { useMediaQuery } from "react-responsive";
import { isDeviceQuery } from "../../../utils/mediaQueries";
import classNames from "classnames/bind";
import * as listingStyles from "./seoCategoryListing.module.css";
const cx = classNames.bind(listingStyles);

export const fragment = graphql`
    fragment SEOCategory on ContentfulSeoCategory {
        id
        name
        title
        description
        buttonText
        images {
            id
        }
        featuredImagesLarge: images {
            # fixed (width: 780, height: 588, transformations: "c_fill")
            public_id
            width
            height
            format
        }
        featuredImagesSmall: images {
            # fixed (width: 368, height: 208, transformations: "c_fill")
            public_id
            width
            height
            format
        }
        imagesAltText
        links {
            ... on ContentfulSeoPage {
                __typename
                contentful_id
                title
                slug
            }
        }
        slug
        icon {
            # fixed (width: 40)
            public_id
            width
            height
            format
        }
        iconAltText
    }
`;

// A single entry in the Content Listing of SEO Categories
const SEOCategory = ({
    title,
    description,
    slug,
    icon,
    iconAltText,
    buttonText,
    links,
    images,
    imagesAltText,
    featuredImagesLarge,
    featuredImagesSmall,
}) => {
    // Set up icon
    const iconStyle = { maxWidth: 40, height: "auto" };

    // Set up i18n context and helper
    const { i18n } = useI18n();

    const featured = images?.map((e, i) => ({
        title: links?.[i]?.title,
        featuredImageLarge: featuredImagesLarge?.[i],
        featuredImageSmall: featuredImagesSmall?.[i],
        featuredImageAltText: imagesAltText?.[i],
        link: getPrefixedPathByPageType(links?.[i]?.slug, links?.[i]?.__typename),
    }));

    return (
        <div className={cx("categoryItem")}>
            <div className={cx("categoryCopy")}>
                {title && (
                    <h2>
                        {icon?.[0] && (
                            <Image
                                placeholderStyle={{ filter: `blur(10px)` }}
                                image={icon?.[0]}
                                imgStyle={iconStyle}
                                alt={iconAltText ?? title}
                                className={cx("categoryIcon")}
                            />
                        )}
                        {title}
                    </h2>
                )}
                {description && <p>{description}</p>}
                <CTA
                    title={buttonText ?? commonTranslations[i18n.curr.langCode].seeAll}
                    variant="Tertiary"
                    url={normalizePath(`/knowledge-center/${slug}/`)}
                />
            </div>
            {featured && (
                <div className={cx("featuredResources")}>
                    {featured.map((entry, index) => (
                        <FeaturedResource {...entry} key={index} index={index} />
                    ))}
                </div>
            )}
        </div>
    );
};

// A single entry of the Featured Resources from an SEO Category
const FeaturedResource = ({ title, link, featuredImageLarge, featuredImageSmall, featuredImageAltText, index }) => {
    // Media query for display size
    const isDevice = useMediaQuery(isDeviceQuery, undefined);

    // Bool for how featured resource is displayed
    const isLarge = index === 0 || isDevice;

    // Class to determine size of entry in the featured section
    const sizeClass = isLarge ? "isLarge" : "isSmall";

    // Set up image, depending on featured image presentation
    const style = isLarge ? { width: 390, height: 294 } : { width: 184, height: 104 };

    // Truncate the title to fit within the given space
    let displayTitle = title;
    if (!isDevice) {
        displayTitle = isLarge ? truncateOnBoundary(title, 70) : truncateOnBoundary(title, 37);
    }

    return (
        <div className={cx("featuredResource", sizeClass, `itemIndex${index}`)}>
            <Link to={link && normalizePath(link)}>
                <Image
                    placeholderStyle={{ filter: `blur(10px)` }}
                    image={isLarge ? featuredImageLarge : featuredImageSmall}
                    style={{
                        ...style,
                        overflow: "hidden",
                    }}
                    imgStyle={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        objectPosition: "center center",
                        maxWidth: "none",
                    }}
                    alt={featuredImageAltText}
                    transforms={["c_fill", "q_auto"]}
                />
                <div>{displayTitle}</div>
            </Link>
        </div>
    );
};

// The overall Content Listing for SEO Categories
const seoCategoryListing = (props) => {
    return (
        <>
            {props.tierOneItems.map((category, i) => (
                <SEOCategory key={i} {...category} />
            ))}
        </>
    );
};

export default seoCategoryListing;
