// extracted by mini-css-extract-plugin
export var blogListItem = "blogCategory-module--blogListItem--daa1f";
export var blogPostContent = "blogCategory-module--blogPostContent--ef4ad";
export var blogthumbnail = "blogCategory-module--blogthumbnail--3ef18";
export var blogthumbnailImage = "blogCategory-module--blogthumbnailImage--6acc0";
export var box = "blogCategory-module--box--23ab3";
export var contentHolder = "blogCategory-module--contentHolder--0a167";
export var list = "blogCategory-module--list--93957";
export var postDate = "blogCategory-module--postDate--dc5ce";
export var postExcerpt = "blogCategory-module--postExcerpt--a8def";
export var postLink = "blogCategory-module--postLink--c8f37";
export var postTitle = "blogCategory-module--postTitle--28440";