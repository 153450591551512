import React from "react";
import { graphql } from "gatsby";
import classNames from "classnames/bind";
import BaseContentListing from "@v4/gatsby-theme-talend/src/components/modules/contentListing/ContentListing";
import CustomerListing from "../../listings/customers/CustomerListing";
/* WPM-1590: Commenting out code related to the Academy Training calendar */
// import CourseListing from "../../listings/academy/CourseListing";
import PartnerListing from "../../listings/partners/PartnerListing";
import EventListing from "../../listings/events/EventListing";
import * as listingStyles from "./contentListing.module.css";
import formatContentfulRichText from "@v4/talend/src/utils/formatContentfulRichText";
import ResourceListing from "../../listings/resources/ResourceListing";
import seoCategoryListing from "./SeoCategoryListing";
import Sitemap from "../../sitemap/Sitemap";
import BlogCategory from "./BlogCategory";
import WebinarListing from "./WebinarListing";
const cx = classNames.bind(listingStyles);

export const fragment = graphql`
    fragment ContentListing on ContentfulContentListing {
        id
        name
        node_locale
        stylingOptions {
            ...stylingOptions
        }
        heading {
            raw
        }
        headingTextShade
        featuredHeading {
            raw
        }
        listContentType
        itemsPerPage
        featuredItems {
            __typename
            ... on Node {
                ...Customer
                ...Partner
                ...Resource
            }
        }
        tierOneItems {
            __typename
            ... on Node {
                ...Customer
                ...Event
                ...Resource
                ...SEOCategory
            }
        }
    }
`;

// Map of listing type to listing react component.
// @todo: remove 'Webinar' option (line 67) once Connect is over. (WPM-1252)

/* WPM-1590: Commenting out code related to the Academy Training calendar
   Migration file created to remove this option from the Content type listing field: 
   `apps/talend/contentful/migrations/contentListing/20230418150757-remove-academy-courses-from-listing.js` 
*/
const listingTypeMap = {
    Customers: CustomerListing,
    // "Academy Courses": CourseListing,
    Partners: PartnerListing,
    Events: EventListing,
    Resources: ResourceListing,
    "SEO Categories": seoCategoryListing,
    Sitemap: Sitemap,
    "Contentful Blog Category": BlogCategory,
    Webinar: WebinarListing,
};

const ContentListing = (props) => (
    <BaseContentListing
        cx={cx}
        formatContentfulRichText={formatContentfulRichText}
        listingTypeMap={listingTypeMap}
        {...props}
    />
);

export default ContentListing;
