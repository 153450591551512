import React from "react";
import Image from "@v4/gatsby-theme-talend/src/components/Image/Image";
import Link from "@v4/gatsby-theme-talend/src/components/Link";
import classNames from "classnames/bind";
import { getPrefixedPathByPageType } from "@v4/utils/pathPrefix";
import * as styles from "./resourceFeaturedCard.module.css";
const cx = classNames.bind(styles);

const ResourceFeaturedCard = ({
    cx,
    title,
    featuredImage,
    featuredImageAltText,
    buttonText,
    pageLink,
    url,
    resourceTypeSpecific,
}) => {
    const path = pageLink ? getPrefixedPathByPageType(pageLink.slug, pageLink.__typename) : url;

    return (
        <Link
            to={path}
            className={cx(
                "resourceFeaturedCard",
                "max-w-lg",
                "mx-auto",
                "flex",
                "flex-col",
                "rounded-md",
                "overflow-hidden"
            )}
        >
            {featuredImage?.[0] ? (
                <Image
                    className="rounded-t-md mb-2"
                    placeholderStyle={{ filter: `blur(10px)` }}
                    image={featuredImage?.[0]}
                    alt={featuredImageAltText}
                    imgStyle={{ width: "100%" }}
                    width={512}
                    height={284}
                    transforms={["q_auto", "c_fill"]}
                />
            ) : (
                <img
                    className="rounded-t-md"
                    src="https://res.cloudinary.com/talend/image/upload/f_auto,q_auto,c_crop,g_center,w_800,ar_1.8/v1615250859/sample.jpg"
                    alt="Featured resource"
                />
            )}
            <div className="pt-1 pr-3 pb-3 pl-3 flex-grow flex flex-col">
                <div className={`${cx("resourceFeaturedCardType")} pb-2`}>{resourceTypeSpecific?.[0]?.name}</div>
                <h4 className="text-c_text_grey mb-6 text-lead font-semibold">{title}</h4>
                <button className={cx("mt-auto", " self-start", "resourceCardButton")}>{buttonText}</button>
            </div>
        </Link>
    );
};

export default ResourceFeaturedCard;
