import React from "react";

const FilterDropdown = ({ cx, value, defaultValue, values, changeHandler, name }) => {
    return (
        <div className={cx("selectWrapper")}>
            <select value={value} id={name} className={cx("formInputDropdown")} name={name} onChange={changeHandler}>
                <option value={defaultValue}>{defaultValue}</option>
                {values.map((val, index) => {
                    if (!val) return null;
                    return (
                        <option key={index} value={val}>
                            {val}
                        </option>
                    );
                })}
            </select>
        </div>
    );
};

export default FilterDropdown;
