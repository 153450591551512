import React, { useEffect, useState, useRef, useCallback } from "react";
import classnames from "classnames/bind";
import { useStaticQuery, graphql } from "gatsby";
import { useI18n } from "@v4/utils/i18nContext";
import * as listingStyles from "./customerListing.module.css";
import FilterGroup from "../../filters/FilterGroup";
import CustomerListingCard from "./CustomerListingCard";
import CustomerFeaturedCard from "./CustomerFeaturedCard";
import { customerTranslations } from "../../../translations/customer";
import scrollWithHeaderOffset from "@v4/utils/scrollWithHeaderOffset";
import { withDefault, NumberParam } from "use-query-params";
import { withQueryParamProvider } from "@v4/utils/withQueryParamProvider";
import { PipeArrayDelimiter, getTermSubTerms, getFilterTaxonomyTree, useQueryParams } from "@v4/utils/filterUtils";

const cx = classnames.bind(listingStyles);

// Customer fragment is used in static query below.
export const fragment = graphql`
    fragment Customer on ContentfulCustomer {
        id
        contentful_id
        slug
        node_locale
        title
        cardHex
        logo {
            public_id
            width # max width of 630px
            height
            format
        }
        cardText {
            raw
        }
        cardBackgroundImage {
            public_id
            width # max width of 735px
            height
            format
        }
        logoAltText
        cardText {
            raw
        }
        taxonomy {
            name
            contentful_id
        }
    }
`;

// A filterable listing of all customers in the current language. Featured items are shown as
// cards along the top, while "tier 1" items show up at the top of the rest of the customers
// in the listing.
const CustomerListing = ({ title, featuredHeading, featuredItems, tierOneItems, itemsPerPage }) => {
    const { i18n } = useI18n();
    const translations = customerTranslations[i18n.curr.langCode];
    const data = useStaticQuery(graphql`
        query CustomersQuery {
            customers: allContentfulCustomer {
                nodes {
                    __typename
                    ... on Node {
                        ...Customer
                    }
                }
            }
            customerTaxonomies: allContentfulTaxonomy(filter: { name: { eq: "Customers" } }) {
                nodes {
                    name
                    node_locale
                    terms {
                        ...TermFields
                        ...SubTermFields
                    }
                }
            }
        }
    `);

    const [allParams, setAllParams] = useQueryParams({
        products: withDefault(PipeArrayDelimiter, []),
        regions: withDefault(PipeArrayDelimiter, []),
        industries: withDefault(PipeArrayDelimiter, []),
        page: withDefault(NumberParam, 1),
    });

    // The list of customers that match the current filters.
    const [customers, setCustomers] = useState([]);

    // The list of currently visible customers depending on pagination.
    const [list, setList] = useState([]);

    // Pagination state.
    const [hasMore, setHasMore] = useState(false);

    // Flags to determine whether we should filter.
    const shouldFilterByProduct = allParams.products?.length;
    const shouldFilterByRegion = allParams.regions?.length;
    const shouldFilterByIndustry = allParams.industries?.length;

    // Get relevant taxonomy terms (Customers in the current language).
    const filterTaxonomyTree = getFilterTaxonomyTree(data.customerTaxonomies, i18n.curr.langCode, "Customers");

    // Product/Region/Industry term list.
    const products = getTermSubTerms(filterTaxonomyTree?.[0].name, filterTaxonomyTree);
    const regions = getTermSubTerms(filterTaxonomyTree?.[1].name, filterTaxonomyTree);
    const industries = getTermSubTerms(filterTaxonomyTree?.[2].name, filterTaxonomyTree);

    // Check if there are more customers to show.
    useEffect(() => {
        const isMore = list.length < customers.length;
        setHasMore(isMore);
    }, [list, customers]);

    const handleFiltering = useCallback(
        (data) => {
            let filteredCustomers = data;

            if (shouldFilterByProduct) {
                filteredCustomers = filteredCustomers.filter(
                    (customer) =>
                        customer.taxonomy && customer.taxonomy.some((term) => allParams?.products.includes(term.name))
                );
            }

            if (shouldFilterByRegion) {
                filteredCustomers = filteredCustomers.filter(
                    (customer) =>
                        customer.taxonomy && customer.taxonomy.some((term) => allParams?.regions.includes(term.name))
                );
            }

            if (shouldFilterByIndustry) {
                filteredCustomers = filteredCustomers.filter(
                    (customer) =>
                        customer.taxonomy && customer.taxonomy.some((term) => allParams?.industries.includes(term.name))
                );
            }

            setCustomers(filteredCustomers);
            setList([...filteredCustomers.slice(0, allParams.page * itemsPerPage)]);
        },
        [itemsPerPage, shouldFilterByIndustry, shouldFilterByProduct, shouldFilterByRegion, allParams]
    );

    useEffect(() => {
        // Grab the ids from tier one items so that we can remove them from the remaining list of customers.
        const tierOneIds = tierOneItems.map((item) => item.contentful_id);

        // Filter out tier one customers so they can be prepended to the list.
        const nonTierOneCustomers = data.customers.nodes
            .filter((customer) => customer.node_locale === i18n.curr.langCode)
            .filter((customer) => !tierOneIds.includes(customer.contentful_id))
            .sort((a, b) => a.title.localeCompare(b.title));

        // All non-featured customers should be handled by the listing/filtering features.
        const allListingCustomers = tierOneItems.concat(nonTierOneCustomers);

        handleFiltering(allListingCustomers);
    }, [data.customers.nodes, tierOneItems, i18n.curr.langCode, handleFiltering]);

    // Filter config to pass to FilterGroup component below.
    const filters = filterTaxonomyTree && [
        {
            title: filterTaxonomyTree[0].name,
            allTerms: products,
            currentValues: allParams?.products,
            setter: (checkedBoxes) => {
                setAllParams({ ...allParams, products: checkedBoxes });
            },
            open: allParams.products?.length > 0,
            name: "products",
            filterType: "Checkbox",
        },
        {
            title: filterTaxonomyTree[1].name,
            allTerms: regions,
            currentValues: allParams?.regions,
            setter: (checkedBoxes) => {
                setAllParams({ ...allParams, regions: checkedBoxes });
            },
            open: allParams.regions?.length > 0,
            name: "regions",
            filterType: "Checkbox",
        },
        {
            title: filterTaxonomyTree[2].name,
            allTerms: industries,
            currentValues: allParams?.industries,
            setter: (checkedBoxes) => {
                setAllParams({ ...allParams, industries: checkedBoxes });
            },
            open: allParams.industries?.length > 0,
            name: "industries",
            filterType: "Checkbox",
        },
    ];

    const resetFilters = () => {
        setAllParams({
            products: [],
            regions: [],
            industries: [],
            page: 1,
        });
    };

    const NoResults = () => {
        return (
            <div className={cx("NoResultsMessage")}>
                <h3 className={cx("subTitle")}>There are currently no customers matching your criteria.</h3>
            </div>
        );
    };

    // Create ref for "return to top" feature.
    const topRef = useRef(null);
    const scrollToTop = () => scrollWithHeaderOffset(topRef.current);

    // Handle "View more" button click
    const handleLoadMore = () => {
        if (hasMore) {
            const currentLength = list.length;
            const isMore = currentLength < customers.length;
            const nextResults = isMore ? customers.slice(currentLength, currentLength + itemsPerPage) : [];
            setList([...list, ...nextResults]);
            setAllParams({ ...allParams, page: allParams.page + 1 });
        }
    };

    const CustomerListingWrapper = ({ customers }) => {
        if (!customers.length) return <NoResults />;
        return (
            <>
                <div className={cx("customerResults")}>
                    {customers.map((customer, index) => (
                        <CustomerListingCard key={`customer-${index}`} {...customer} />
                    ))}
                </div>
                <div className={cx(`controlButtons`)}>
                    {hasMore && (
                        <button className={cx(`loadMoreButton`, `btn`, `btnTertiary`)} onClick={handleLoadMore}>
                            {translations.viewMore}
                        </button>
                    )}
                    {allParams.page > 1 && (
                        <button className={cx(`backToTop`, `btn`, `btnTertiary`)} onClick={scrollToTop}>
                            {translations.toTop}
                        </button>
                    )}
                </div>
            </>
        );
    };

    const FeaturedCustomers = ({ featuredItems }) => (
        <div className={cx(`featuredCustomers`)}>
            {featuredItems.map((item, index) => (
                <CustomerFeaturedCard key={`customerFeature-${index}`} {...item} />
            ))}
        </div>
    );

    return (
        <>
            <div ref={topRef} className={cx(`customerFeaturedHeader`)}>
                {featuredHeading}
            </div>
            <FeaturedCustomers featuredItems={featuredItems} />
            <div className={cx(`customerFilterHeader`)}>{title}</div>
            <div className={cx(`customerListing`)}>
                {filters && <FilterGroup filters={filters} resetFunction={resetFilters} />}

                <div className={cx(`customerListingResults`)}>
                    <CustomerListingWrapper customers={list} />
                </div>
            </div>
        </>
    );
};

export default withQueryParamProvider(CustomerListing);
