import React, { useRef, useState, useEffect, useCallback } from "react";
import FilterArrow from "../../assets/svg/filter-arrow.inline.svg";

const FilterCheckboxes = ({ cx, FilterCheckboxTree, filter, filterID }) => {
    const { title, allTerms, currentValues, setter, defaultValue = null, open = false, name } = filter;
    const [accordionIsOpen, setAccordionIsOpen] = useState(false);
    const filterWrapperRef = useRef();
    let isAnimating = useRef(false);
    const isFirstRender = useRef(true);

    const handleCheckbox = (e) => {
        const { checked } = e.target;
        const checkedValue = e.currentTarget.dataset.value;
        const checkedBoxes = currentValues ? [...currentValues] : [];

        if (checked) {
            checkedBoxes.push(checkedValue);
        } else {
            const index = checkedBoxes.findIndex((ch) => ch === checkedValue);
            checkedBoxes.splice(index, 1);
        }

        setter(checkedBoxes);
    };

    // Returns the full height of the interior element, in pixels
    const getInteriorElHeight = (el) => {
        return `${el.scrollHeight}px`;
    };

    // Callback func to handle tail of accordion opening animation
    const transEnd = useCallback((event) => {
        // Reset height to "auto" to handle any interior content shifts
        event.target.style.height = "auto";

        // Remove callback so elements are free to flow when needed
        event.target.removeEventListener("transitionend", transEnd);

        // End animation, change flag
        isAnimating.current = false;
    }, []);

    // Accordion Function
    const toggleAccordion = useCallback(
        (e, refEl) => {
            // Get the wrapper element
            const filterWrapper = refEl.current;

            if (isAnimating.current === false) {
                // Animation starting, change flag
                isAnimating.current = true;

                // If the filter is open, close it by transitioning from an explicit height down to 0
                if (accordionIsOpen) {
                    // Closing the filter --
                    // Define initial starting height
                    filterWrapper.style.height = getInteriorElHeight(filterWrapper);

                    // Closing: At the next frame, un-set the height (CSS sets to 0)
                    requestAnimationFrame(() => {
                        filterWrapper.style.height = null;

                        // Completed animation, update flag
                        isAnimating.current = false;
                    });
                } else {
                    // Opening the filter --
                    // Define height value which transitions from 0
                    filterWrapper.style.height = getInteriorElHeight(filterWrapper);

                    // Assign event listener
                    filterWrapper.addEventListener("transitionend", transEnd);
                }

                // Update state to match the Accordion
                setAccordionIsOpen(!accordionIsOpen);
            }
        },
        [accordionIsOpen, transEnd]
    );

    useEffect(() => {
        if (defaultValue) {
            const checkedBoxes = [...defaultValue];
            setter(checkedBoxes);
        }
    }, [setter, defaultValue]);

    // If there is something present in the query string aka open is true. Open the accordion on first render.
    useEffect(() => {
        if (isFirstRender.current && open && !accordionIsOpen) {
            setAccordionIsOpen(true);
            toggleAccordion(null, filterWrapperRef);
        }
    }, [open, accordionIsOpen, toggleAccordion]);

    // After the first render set the ref to false
    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
        }
    }, []);

    return (
        <>
            <span className={cx(`filterHeading`)} onClick={(e) => toggleAccordion(e, filterWrapperRef)}>
                {title} <FilterArrow style={accordionIsOpen ? { transform: `rotate(180deg)` } : {}} />
            </span>
            <div className={cx(`filterWrapper`)} ref={filterWrapperRef}>
                <FilterCheckboxTree
                    data={allTerms}
                    handler={handleCheckbox}
                    currentValues={currentValues ? [...currentValues] : []}
                    level={0}
                    groupName={name}
                />
            </div>
        </>
    );
};

export default FilterCheckboxes;
