import React from "react";
import { QueryParamProvider } from "use-query-params";
import { globalHistory, Location } from "@reach/router";
import { navigate } from "gatsby";

let cachedReachHistory = undefined;
let cachedAdaptedReachHistory = undefined;

/**
 * Adapt @reach/router history to work with use-query-params' history
 * interface.
 *
 * This version uses @reach/router's `navigate` and relative URLs to avoid
 * 404s.
 *
 * @param history globalHistory from @reach/router
 */
const adaptReachHistory = (history) => {
    if (history === cachedReachHistory && cachedAdaptedReachHistory != null) {
        return cachedAdaptedReachHistory;
    }

    const adaptedReachHistory = {
        async push(location) {
            await navigate(location.search || location.pathname, {
                replace: false,
                // Set state value to avoid scroll position changes for filters
                state: {
                    disableScrollUpdate: true,
                    xPos: window.scrollX,
                    yPos: window.scrollY,
                },
            });
        },
        async replace(location) {
            await navigate(location.search || location.pathname, {
                replace: true,
                // Set state value to avoid scroll position changes for filters
                state: {
                    disableScrollUpdate: true,
                    xPos: window.scrollX,
                    yPos: window.scrollY,
                },
            });
        },
        get location() {
            return history.location;
        },
    };

    cachedReachHistory = history;
    cachedAdaptedReachHistory = adaptedReachHistory;

    return adaptedReachHistory;
};

export const withQueryParamProvider = (Component) => (props) => (
    <Location>
        {({ location }) => {
            return (
                <QueryParamProvider location={location} history={adaptReachHistory(globalHistory)}>
                    <Component {...props} />
                </QueryParamProvider>
            );
        }}
    </Location>
);
