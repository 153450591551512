import React from "react";
import truncate from "@v4/utils/truncate";
import Link from "@v4/gatsby-theme-talend/src/components/Link";
import { getPrefixedPathByPageType } from "@v4/utils/pathPrefix";

export const Resource = ({
    cx,
    title,
    gradientClass,
    summary,
    resourceType,
    resourceTypeSpecific,
    buttonText,
    pageLink,
    url,
}) => {
    const path = pageLink ? getPrefixedPathByPageType(pageLink.slug, pageLink.__typename) : url;

    return (
        <Link className={cx("resourceCard")} to={path}>
            <div className="flex flex-col flex-1 flex-grow h-full rounded-md">
                <div className={cx("resourceType", "rounded-t-md", "p-2", "text-lead", "text-white", gradientClass)}>
                    {resourceType?.[0]?.name}
                </div>
                <div className={cx("cardBody", "flex", "flex-1", "h-full", "flex-col", "py-3", "px-2", "text-base")}>
                    <div className={`text-base mb-2 ${cx("resourceTypeSpecific")}`}>
                        {resourceTypeSpecific?.[0]?.name}
                    </div>
                    {title && <h4 className="text-h3 mb-2 font-semibold">{truncate(title, 50)}</h4>}
                    {summary && <p className="mt-0">{truncate(summary, 150)}</p>}
                </div>
                <div className="pb-3 px-2 text-lead">
                    <button className={cx("mt-auto", " self-start", "resourceCardButton")}>{buttonText}</button>
                </div>
            </div>
        </Link>
    );
};
