import React, { useRef, useEffect } from "react";

const FilterSearch = ({ cx, filter }) => {
    const { setter, name, placeholder, currentValue } = filter;
    const inputEl = useRef(null);

    useEffect(() => {
        if (currentValue.length > 2) {
            inputEl.current.value = currentValue;
        }
    }, [currentValue]);

    const handleInputChange = (e, setter) => {
        e.preventDefault();
        if (e.target.value.length > 2) {
            setter(e.target.value);
        } else {
            setter("");
        }
    };

    return (
        <div className={cx("searchWrapper")}>
            <input
                ref={inputEl}
                id={name}
                className="searchBox"
                placeholder={placeholder}
                onChange={(e) => handleInputChange(e, setter)}
            />
        </div>
    );
};

export default FilterSearch;
