import React, { useEffect, useState, useRef, useCallback, useMemo } from "react";
import classnames from "classnames/bind";
import { useStaticQuery, graphql } from "gatsby";
import { documentToPlainTextString } from "@contentful/rich-text-plain-text-renderer";
import { useI18n } from "@v4/utils/i18nContext";
import * as listingStyles from "./partnerListing.module.css";
import FilterGroup from "../../filters/FilterGroup";
import PartnerListingCard from "./PartnerListingCard";
import { partnerListingTranslations } from "../../../translations/partnerListing";
import scrollWithHeaderOffset from "@v4/utils/scrollWithHeaderOffset";
import { ValidFilterContext } from "@v4/gatsby-theme-talend/src/components/filters/ValidFilterContext";
import { withDefault, NumberParam, StringParam } from "use-query-params";
import { withQueryParamProvider } from "@v4/utils/withQueryParamProvider";
import { PipeArrayDelimiter, getTermSubTerms, getFilterTaxonomyTree, useQueryParams } from "@v4/utils/filterUtils";

const cx = classnames.bind(listingStyles);

// Partner fragment is used in static query below.
export const fragment = graphql`
    fragment Partner on ContentfulPartner {
        contentful_id
        node_locale
        title
        logo: logo {
            # fixed(width: 336, height: 164, transformations: "f_auto,q_auto,c_pad")
            public_id
            width
            height
            format
        }
        modalLogo: logo {
            # fixed(height: 80)
            public_id
            width
            height
            format
        }
        logoAltText
        url
        description {
            raw
        }
        partnerAccreditations {
            name
            image {
                # fixed (width: 100, height: 115)
                public_id
                width
                height
                format
            }
        }
        partnerType {
            name
            contentful_id
        }
        partnerLevel {
            name
            contentful_id
        }
        partnerProficiencies {
            name
            contentful_id
        }
        partnerProductProficiencies {
            name
            contentful_id
        }
        partnerRegions {
            name
            contentful_id
        }
    }
`;

// A filterable listing of all partners in the current language.
const PartnerListing = ({ title, featuredHeading, featuredItems, itemsPerPage }) => {
    const { i18n } = useI18n();
    const data = useStaticQuery(graphql`
        query PartnersQuery {
            partners: allContentfulPartner {
                nodes {
                    __typename
                    ... on Node {
                        ...Partner
                    }
                }
            }
            partnerTaxonomies: allContentfulTaxonomy(filter: { name: { eq: "Partners" } }) {
                nodes {
                    name
                    node_locale
                    terms {
                        ...TermFields
                        ...SubTermFieldsRecursive
                    }
                }
            }
        }
    `);

    const [allParams, setAllParams] = useQueryParams({
        partnerTypes: withDefault(PipeArrayDelimiter, []),
        partnerLevels: withDefault(PipeArrayDelimiter, []),
        partnerProficiencies: withDefault(PipeArrayDelimiter, []),
        partnerRegions: withDefault(PipeArrayDelimiter, []),
        partnerSearch: withDefault(StringParam, ""),
        page: withDefault(NumberParam, 1),
    });

    // The list of partners that match the current filters.
    const [partners, setPartners] = useState([]);

    // Valid filters based on current selections.
    const [validFilters, setValidFilters] = useState({});

    // The list of currently visible partners depending on pagination.
    const [list, setList] = useState([]);

    // Pagination state.
    const [hasMore, setHasMore] = useState(false);

    // Flags to determine whether we should filter.
    const shouldFilterByPartnerType = allParams.partnerTypes?.length;
    const shouldFilterByPartnerLevel = allParams.partnerLevels?.length;
    const shouldFilterByPartnerProficiencies = allParams.partnerProficiencies?.length;
    const shouldFilterByPartnerRegion = allParams.partnerRegions?.length;
    // Only filter with the search box if it has more than 2 letters
    const shouldFilterByPartnerSearch = allParams.partnerSearch?.length > 2;

    // Get relevant taxonomy terms (Partners in the current language).
    const filterTaxonomyTree = getFilterTaxonomyTree(data.partnerTaxonomies, i18n.curr.langCode, "Partners");

    // Get relevant taxonomy terms (Partners in english to filter out the correct terms).
    const englishTaxonomyTree = getFilterTaxonomyTree(data.partnerTaxonomies, "en", "Partners");

    const englishPartnerTypes = getTermSubTerms(englishTaxonomyTree?.[0].name, englishTaxonomyTree, false);
    const englishPartnerLevels = getTermSubTerms(englishTaxonomyTree?.[1].name, englishTaxonomyTree, false);

    const partnerTermLanguageMap = (term, englishTerms, currentLanguageTerms) => {
        const termId = englishTerms.find((partnerTermsWithId) => partnerTermsWithId.name === term)?.contentful_id;
        return currentLanguageTerms.find((term) => term.contentful_id === termId);
    };

    // Filter Terms
    const partnerTypes = getTermSubTerms(filterTaxonomyTree?.[0].name, filterTaxonomyTree, false);
    const partnerLevels = getTermSubTerms(filterTaxonomyTree?.[1].name, filterTaxonomyTree, false);
    const partnerProficiencies = getTermSubTerms(filterTaxonomyTree?.[2].name, filterTaxonomyTree, false);
    const partnerRegions = getTermSubTerms(filterTaxonomyTree?.[3].name, filterTaxonomyTree, false);

    // Get all partner in the current language and sort alpha
    const allPartners = useMemo(
        () =>
            data.partners.nodes
                .filter((partner) => partner.node_locale === i18n.curr.langCode)
                .sort((a, b) => a?.title?.localeCompare(b?.title)),
        [data.partners.nodes, i18n.curr.langCode]
    );

    const filterPartners = useCallback(
        (data, filterBy) => {
            let filteredPartners = data;

            // Filter data on a certain filter type using certain filter values. E.g. if `filterType` is partnerLevel then `filter` should
            // be the partnerLevelFilter. If the partnerLevelFilter looked like ['Gold','Platinum'] then we'd filter out all items that
            // don't have either gold or platinum in their partner.partnerLevel array.
            const applyFilters = (filterType, filter, data) =>
                data.filter((partner) => partner?.[filterType]?.some((term) => filter.includes(term.name)));

            if (filterBy.partnerType) {
                filteredPartners = applyFilters("partnerType", allParams?.partnerTypes, filteredPartners);
            }

            if (filterBy.partnerLevel) {
                filteredPartners = applyFilters("partnerLevel", allParams?.partnerLevels, filteredPartners);
            }

            if (filterBy.partnerProficiencies) {
                filteredPartners = applyFilters(
                    "partnerProficiencies",
                    allParams?.partnerProficiencies,
                    filteredPartners
                );
            }

            if (filterBy.partnerRegions) {
                filteredPartners = applyFilters("partnerRegions", allParams?.partnerRegions, filteredPartners);
            }

            if (filterBy.partnerSearch) {
                filteredPartners = filteredPartners.filter((partner) => {
                    let title = partner?.title;
                    let searchDescription = partner?.description?.raw;
                    searchDescription = searchDescription
                        ? documentToPlainTextString(JSON.parse(searchDescription))
                        : "";

                    return `${title} ${searchDescription}`
                        .toLowerCase()
                        .includes(allParams?.partnerSearch.toLowerCase());
                });
            }

            return filteredPartners;
        },
        [allParams]
    );

    useEffect(() => {
        // Partners based on selected filter values
        const filteredPartners = filterPartners(allPartners, {
            partnerType: shouldFilterByPartnerType,
            partnerLevel: shouldFilterByPartnerLevel,
            partnerProficiencies: shouldFilterByPartnerProficiencies,
            partnerRegions: shouldFilterByPartnerRegion,
            partnerSearch: shouldFilterByPartnerSearch,
            page: allParams?.currentPage,
        });

        // Update the partner listing according to the selected filters
        setPartners(filteredPartners);
        setList([...filteredPartners.slice(0, allParams?.page * itemsPerPage)]);
    }, [
        allPartners,
        filterPartners,
        shouldFilterByPartnerType,
        shouldFilterByPartnerLevel,
        shouldFilterByPartnerProficiencies,
        shouldFilterByPartnerRegion,
        shouldFilterByPartnerSearch,
        itemsPerPage,
        allParams,
    ]);

    // Check if there are more partners to show.
    useEffect(() => {
        const isMore = list.length < partners.length;
        setHasMore(isMore);
    }, [list, partners]);

    // Disable checkboxes based on what's available with the current filters.
    useEffect(() => {
        const filters = ["partnerType", "partnerLevel", "partnerProficiencies", "partnerRegions"];

        // Valid filters are defined as any filters that will not result in "no result" based on the current filter
        // selections. We determine which filters are valid by looking at each filter one at a time, applying
        // every other filter, then looping over the remaining partners and seeing which values still exist for the
        // current filter.
        const validFilters = {};
        filters.forEach((currFilterType, i, arr) => {
            const filtered = filterPartners(allPartners, {
                partnerType: shouldFilterByPartnerType && currFilterType !== "partnerType",
                partnerLevel: shouldFilterByPartnerLevel && currFilterType !== "partnerLevel",
                partnerProficiencies: shouldFilterByPartnerProficiencies && currFilterType !== "partnerProficiencies",
                partnerRegions: shouldFilterByPartnerRegion && currFilterType !== "partnerRegions",
                partnerSearch: shouldFilterByPartnerSearch,
            });

            filtered.forEach((partner) => {
                partner?.[currFilterType]?.forEach((term) => {
                    validFilters[term.name] = true;
                });
            });
        });

        setValidFilters(validFilters);
    }, [
        allPartners,
        filterPartners,
        itemsPerPage,
        shouldFilterByPartnerType,
        shouldFilterByPartnerLevel,
        shouldFilterByPartnerProficiencies,
        shouldFilterByPartnerRegion,
        shouldFilterByPartnerSearch,
    ]);

    const partnerTranslations = partnerListingTranslations[i18n.curr.langCode];

    // Filter config to pass to FilterGroup component below.
    const filterGroups = filterTaxonomyTree && [
        {
            title: partnerTranslations.search,
            currentValue: allParams?.partnerSearch,
            setter: (value) => {
                setAllParams({ ...allParams, partnerSearch: value });
            },
            name: "partnerSearch",
            filterType: "Search",
            placeholder: partnerTranslations.search,
        },
        {
            title: filterTaxonomyTree[0].name,
            allTerms: partnerTypes,
            currentValues: allParams?.partnerTypes,
            setter: (checkedBoxes) => {
                setAllParams({ ...allParams, partnerTypes: checkedBoxes });
            },
            open: allParams?.partnerTypes?.length > 0,
            name: "partnerTypes",
            filterType: "Checkbox",
        },
        {
            title: filterTaxonomyTree[1].name,
            allTerms: partnerLevels,
            currentValues: allParams?.partnerLevels,
            setter: (checkedBoxes) => {
                setAllParams({ ...allParams, partnerLevels: checkedBoxes });
            },
            open: allParams?.partnerLevels?.length > 0,
            name: "partnerLevels",
            filterType: "Checkbox",
        },
        {
            title: filterTaxonomyTree[2].name,
            allTerms: partnerProficiencies,
            currentValues: allParams?.partnerProficiencies,
            setter: (checkedBoxes) => {
                setAllParams({ ...allParams, partnerProficiencies: checkedBoxes });
            },
            open: allParams?.partnerProficiencies?.length > 0,
            name: "partnerProficiencies",
            filterType: "Checkbox",
        },
        {
            title: filterTaxonomyTree[3].name,
            allTerms: partnerRegions,
            currentValues: allParams?.partnerRegions,
            setter: (checkedBoxes) => {
                setAllParams({ ...allParams, partnerRegions: checkedBoxes });
            },
            open: allParams?.partnerRegions?.length > 0,
            name: "partnerRegions",
            filterType: "Checkbox",
        },
    ];

    const resetFilters = () => {
        setAllParams(
            {
                partnerTypes: [],
                partnerLevels: [],
                partnerProficiencies: [],
                partnerRegions: [],
                partnerSearch: undefined,
                page: 1,
            },
            "replaceIn"
        );
        resetSearchInput();
    };

    const NoResults = () => {
        return (
            <div className={cx("NoResultsMessage")}>
                <h3 className={cx("subTitle")}>{partnerTranslations.noResultsMessage}</h3>
            </div>
        );
    };

    // Hamdles partner unfiltered view open and close section action
    const handlePartnerSectionViewMore = (open, setter, refEl) => {
        if (open === true) {
            scrollWithHeaderOffset(refEl.current);
        }
        setter(!open);
    };

    const PartnerSection = ({ partners, sectionTitle }) => {
        const sectionTop = useRef();
        const [open, setOpen] = useState(false);
        const filtered = !open ? partners.slice(0, 3) : partners;
        const hasMorePartners = partners.length > 3;
        const btnLabel = !open ? partnerTranslations.viewAll : partnerTranslations.viewLess;

        if (!partners.length) return null;

        return (
            <>
                <div ref={sectionTop} className={cx(`partnerFilterHeader`)}>
                    {/* 
                    The title can be from contentful RTE which is an array. 
                    Otherwise it's a string from the translations, so wrap it in <h3> tags. 
                    */}
                    {sectionTitle && Array.isArray(sectionTitle) ? sectionTitle : <h3>{sectionTitle}</h3>}
                </div>
                <div className={cx("partnerResults")}>
                    {filtered &&
                        filtered.map((partner, index) => <PartnerListingCard key={`partner-${index}`} {...partner} />)}
                </div>
                <div className={cx(`controlButtons`)}>
                    {hasMorePartners && (
                        <button
                            className={cx(`loadMoreButton`, `btn`, `btnTertiary`)}
                            onClick={() => handlePartnerSectionViewMore(open, setOpen, sectionTop)}
                        >
                            {btnLabel}
                        </button>
                    )}
                </div>
            </>
        );
    };

    const StartPartnerLayout = ({ partners }) => {
        const systemIntegrators = partners.filter(
            (partner) =>
                partner.partnerType?.[0].contentful_id ===
                partnerTermLanguageMap("Global system integrator", englishPartnerTypes, partnerTypes)?.contentful_id
        );
        const platinumPartners = partners.filter(
            (partner) =>
                partner.partnerLevel?.[0].contentful_id ===
                partnerTermLanguageMap("Platinum", englishPartnerLevels, partnerLevels)?.contentful_id
        );
        const goldPartners = partners.filter(
            (partner) =>
                partner.partnerLevel?.[0].contentful_id ===
                partnerTermLanguageMap("Gold", englishPartnerLevels, partnerLevels)?.contentful_id
        );

        return (
            <>
                <PartnerSection
                    partners={systemIntegrators}
                    sectionTitle={partnerTranslations["Global system integrator"]}
                />
                <PartnerSection partners={featuredItems} sectionTitle={featuredHeading} />
                <PartnerSection partners={platinumPartners} sectionTitle={partnerTranslations["Platinum"]} />
                <PartnerSection partners={goldPartners} sectionTitle={partnerTranslations["Gold"]} />
            </>
        );
    };

    const resetSearchInput = () => {
        Array.from(document.querySelectorAll(".searchBox")).forEach((el) => (el.value = ""));
    };

    // Create ref for "return to top" feature.
    const topRef = useRef(null);
    const scrollToTop = () => scrollWithHeaderOffset(topRef.current);

    // Handle "View more" button click
    const handleLoadMore = () => {
        if (hasMore) {
            const currentLength = list.length;
            const isMore = currentLength < partners.length;
            const nextResults = isMore ? partners.slice(currentLength, currentLength + itemsPerPage) : [];
            setList([...list, ...nextResults]);
            setAllParams({
                ...allParams,
                page: allParams.page + 1,
            });
        }
    };

    const PartnerListingWrapper = ({ partners }) => {
        if (!partners.length) return <NoResults />;
        if (
            partners.length &&
            !shouldFilterByPartnerType &&
            !shouldFilterByPartnerLevel &&
            !shouldFilterByPartnerProficiencies &&
            !shouldFilterByPartnerRegion &&
            !shouldFilterByPartnerSearch
        ) {
            return <StartPartnerLayout partners={allPartners} />;
        }
        return (
            <>
                <div className={cx("partnerResults")}>
                    {partners.map((partner, index) => (
                        <PartnerListingCard key={`partner-${index}`} {...partner} />
                    ))}
                </div>
                <div className={cx(`controlButtons`)}>
                    {hasMore && (
                        <button className={cx(`loadMoreButton`, `btn`, `btnTertiary`)} onClick={handleLoadMore}>
                            {partnerTranslations.viewMore}
                        </button>
                    )}
                    {allParams.page > 1 && (
                        <button className={cx(`backToTop`, `btn`, `btnTertiary`)} onClick={scrollToTop}>
                            {partnerTranslations.toTop}
                        </button>
                    )}
                </div>
            </>
        );
    };

    return (
        <>
            <div ref={topRef} className={cx(`partnerFilterHeader`)}>
                {title}
            </div>
            <div className={cx(`partnerListing`)}>
                <ValidFilterContext.Provider value={validFilters}>
                    {filterGroups && <FilterGroup filters={filterGroups} resetFunction={resetFilters} />}
                </ValidFilterContext.Provider>
                <div className={cx(`partnerListingResults`)}>
                    <PartnerListingWrapper partners={list} />
                </div>
            </div>
        </>
    );
};

export default withQueryParamProvider(PartnerListing);
