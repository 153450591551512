import React from "react";
import classNames from "classnames/bind";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import formatContentfulRichText from "@v4/talend/src/utils/formatContentfulRichText";
import Image from "@v4/gatsby-theme-talend/src/components/Image/Image";
import Link from "@v4/gatsby-theme-talend/src/components/Link";
import * as listingStyles from "./customerFeaturedCard.module.css";
import { customerTranslations } from "../../../translations/customer";
import { useI18n } from "@v4/utils/i18nContext";

const cx = classNames.bind(listingStyles);

const CustomerFeaturedCard = ({ cardText, logo, logoAltText, cardBackgroundImage, slug }) => {
    const { i18n } = useI18n();
    const translations = customerTranslations[i18n.curr.langCode];
    // Setup images
    const logoSrc = logo && logo[0] ? logo[0] : false;

    const imageStyles = logo
        ? {
              width: "100%",
              height: "auto",
          }
        : false;

    const bgImage = cardBackgroundImage && cardBackgroundImage[0] ? cardBackgroundImage[0] : false;

    // Setup card description
    const cardBody = cardText && cardText.raw ? renderRichText(cardText, formatContentfulRichText()) : "";

    // Logo Image Element
    const logoEl = logoSrc && (
        <Image
            placeholderStyle={{ filter: `blur(10px)` }}
            image={logoSrc}
            imgStyle={{
                objectFit: "contain",
                objectPosition: "left center",
                margin: "0",
            }}
            style={imageStyles}
            className={cx("logo")}
            alt={logoAltText}
        />
    );

    // Background Image Element
    const bgImageEl = bgImage && (
        <Image
            placeholderStyle={{ filter: `blur(10px)` }}
            image={bgImage}
            className={cx("curtain")}
            style={{ position: "absolute", width: "100%", height: "100%" }}
            imgStyle={{ width: "100%", height: "100%" }}
            alt={logoAltText}
        />
    );

    // Content Element
    const cardContentEl = cardBody && (
        <div className={cx("contentWrapper")}>
            {logoEl}
            <div className={cx("copy")}>{cardBody}</div>
            <div className={cx("buttonContainer")}>
                <Link className={cx(`customerButton`, `btn`, `btnTertiary`)}>{translations.readStory}</Link>
            </div>
        </div>
    );

    // Customer Card Element
    const card = (
        <>
            {bgImageEl}
            {cardContentEl}
        </>
    );

    return (
        <Link to={slug} className={cx("customerCardOuter")}>
            {card}
        </Link>
    );
};

export default CustomerFeaturedCard;
