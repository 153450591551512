import React from "react";
import classnames from "classnames/bind";
import FilterCheckbox from "./FilterCheckbox";
import BaseFilterCheckboxTree from "@v4/gatsby-theme-talend/src/components/filters/FilterCheckboxTree";
import * as checkboxFilterStyles from "./checkboxFilter.module.css";

// cx for CSS Modules
const cx = classnames.bind(checkboxFilterStyles);

const FilterCheckboxTree = (props) => <BaseFilterCheckboxTree cx={cx} FilterCheckbox={FilterCheckbox} {...props} />;

export default FilterCheckboxTree;
