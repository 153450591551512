import React from "react";
import classNames from "classnames/bind";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import formatContentfulRichText from "@v4/talend/src/utils/formatContentfulRichText";
import Image from "@v4/gatsby-theme-talend/src/components/Image/Image";
import Link from "@v4/gatsby-theme-talend/src/components/Link";
import * as customerCardStyles from "./customerListingCard.module.css";
import { customerTranslations } from "../../../translations/customer";
import { useI18n } from "@v4/utils/i18nContext";

const cx = classNames.bind(customerCardStyles);

const CustomerListingCard = ({ slug, cardHex, logo, cardText, logoAltText }) => {
    const { i18n } = useI18n();
    const translations = customerTranslations[i18n.curr.langCode];
    // Hover Color
    const bgColor = cardHex ? { background: `#${cardHex}` } : {};

    // Setup image
    const image = logo && logo[0] ? logo[0] : false;
    const imageStyles = logo
        ? {
              width: "100%",
              height: "100%",
              maxWidth: "180px",
              maxHeight: "72px",
              zIndex: 2,
          }
        : false;

    // Setup card description
    const desc = cardText && cardText.raw ? renderRichText(cardText, formatContentfulRichText()) : "";

    // Produce image element
    const imageEl = (
        <div className={cx("customerCardImage")}>
            {image && (
                <Image
                    className={cx("customerListingCardLogo")}
                    placeholderStyle={{ filter: `blur(10px)` }}
                    imgStyle={{
                        objectFit: "contain",
                        objectPosition: "center center",
                        width: "100%",
                        height: "100%",
                    }}
                    style={imageStyles}
                    image={image}
                    alt={logoAltText}
                />
            )}
            <div className={cx("customerCardCurtain")} style={bgColor} />
        </div>
    );

    // Produce main card markup
    const contentEl = (
        <div className={cx("customerListingCardContent")}>
            {desc}
            <span className={cx("readMore")}>{translations.readMore}</span>
        </div>
    );

    return (
        <>
            <Link to={slug} className={cx(`customerLink`)}>
                {image && imageEl}
                {contentEl}
            </Link>
        </>
    );
};

export default CustomerListingCard;
