// @todo: Remove this component once Connect is over (WPM-1252 & WPM-1279).
// Also remove 'Webinar' entry from the listingTypeMap in ./contentListng.js line 67.
import React from "react";
import { useI18n } from "@v4/utils/i18nContext";
import { commonTranslations } from "@v4/gatsby-theme-talend/src/translations/common";
import SingleWebinarItem from "../singleWebinarItem/SingleWebinarItem";
import classNames from "classnames/bind";
import * as webinarListingStyles from "./webinarListing.module.css";

// CX binding method for styles
const cx = classNames.bind(webinarListingStyles);

const WebinarListing = (props) => {
    const { i18n } = useI18n();
    const translations = commonTranslations[i18n.curr.langCode];

    /** Following a specific layout for the Connect page
     *  Refer to Miro board: https://miro.com/app/board/uXjVOcI4xJo=/ or WPM-1252
     *  Only display sections if the content exists.
     */
    return (
        <>
            {/* Assume that if the featuredHeading exists, that we're dealing with Content Listing No.1 in Miro.
                Only show the featuredItems grid if the featured heading exists. (Ok to do this?) */}
            {props.featuredHeading && (
                <div className={cx("webinarListingTitle", "featuredTitle")}>{props.featuredHeading}</div>
            )}
            {props.featuredItems && (
                <div className={cx("webinarListingGrid", "featuredGrid")}>
                    {props.featuredItems.map((item, index) => {
                        /* Will the button text always be "Watch now" ? */
                        return (
                            <SingleWebinarItem
                                key={index}
                                {...item}
                                presentation="Featured"
                                buttonText={translations.watchNow}
                            />
                        );
                    })}
                </div>
            )}
            {/* Assume that if the title exists, that we're dealing with TierOne items from either Content Listing No.1 or No.2 in Miro.
                Only show the TierOne grid if the title (Heading field in Contentful) exists. (Ok to do this?) */}
            {props.title && <div className={cx("webinarListingTitle")}>{props.title}</div>}
            {props.tierOneItems && (
                <div className={cx("webinarListingGrid")}>
                    {props.tierOneItems.map((item, index) => {
                        /* Will the button text always be "Watch now" ? */
                        return <SingleWebinarItem key={index} {...item} buttonText={translations.watchNow} />;
                    })}
                </div>
            )}
        </>
    );
};

export default WebinarListing;
