import { commonTranslations as common } from "@v4/gatsby-theme-talend/src/translations/common";

export const partnerListingTranslations = {
    en: {
        search: "Search",
        noResultsMessage: "There are currently no partners matching your criteria.",
        viewMore: common.en.viewMore,
        toTop: common.en.toTop,
        "Global system integrator": "Global system integrators",
        Platinum: "Platinum partners",
        Gold: "Gold partners",
        viewAll: "View all",
        viewLess: "View less",
    },
    "en-GB": {
        search: "Search",
        noResultsMessage: "There are currently no partners matching your criteria.",
        viewMore: common["en-GB"].viewMore,
        toTop: common["en-GB"].toTop,
        "Global system integrator": "Global system integrators",
        Platinum: "Platinum partners",
        Gold: "Gold partners",
        viewAll: "View all",
        viewLess: "View less",
    },
    fr: {
        search: "Recherche",
        noResultsMessage: "Il n'y a actuellement aucun partenaire correspondant à vos critères.",
        viewMore: common.fr.viewMore,
        toTop: common.fr.toTop,
        "Global system integrator": "Intégrateurs systèmes globaux (GSI)",
        Platinum: "Partenaires Platinum",
        Gold: "Partenaires Gold",
        viewAll: "Voir tout",
        viewLess: "Afficher moins",
    },
    de: {
        search: "Suche",
        noResultsMessage: "Es gibt derzeit keine Partner, die Ihren Kriterien entsprechen.",
        viewMore: common.de.viewMore,
        toTop: common.de.toTop,
        "Global system integrator": "Globale Systemintegratoren",
        Platinum: "Platinum-Partner",
        Gold: "Gold-Partner",
        viewAll: "Alles anzeigen",
        viewLess: "Weniger anzeigen",
    },
    it: {
        search: "Cerca",
        noResultsMessage: "Attualmente non ci sono partner corrispondenti ai tuoi criteri.",
        viewMore: common.it.viewMore,
        toTop: common.it.toTop,
        "Global system integrator": "System Integrator Globali",
        Platinum: "Partner Platinum",
        Gold: "Partner Gold",
        viewAll: "Vedi tutto",
        viewLess: "Mostra meno",
    },
    ja: {
        search: "検索",
        noResultsMessage: "「現在、あなたの基準に合ったパートナーはありません」",
        viewMore: common.ja.viewMore,
        toTop: common.ja.toTop,
        "Global system integrator": "GSI",
        Platinum: "プラチナパートナー",
        Gold: "ゴールドパートナー",
        viewAll: "すべて表示",
        viewLess: "少なく表示する",
    },
};
