import React from "react";
import { useI18n } from "@v4/utils/i18nContext";

const resetLabel = {
    en: ["Reset"],
    fr: ["Réinitialiser"],
    de: ["Zurücksetzen"],
    it: ["Reset"],
    ja: ["リセット"],
    "en-GB": ["Reset"],
};

const FilterGroup = ({ cx, filters, filterTypeMap, resetFunction }) => {
    const { i18n } = useI18n();

    return (
        <div className={cx("filterWrapper", "flex", "flex-col", "text-left", "pr-4", "pb-4", "md:w-1/4")}>
            {filters.map((filterGroup, index) => {
                if (!filterGroup) return null;
                const FilterType = filterTypeMap[filterGroup.filterType];
                return <FilterType key={index} filter={filterGroup} filterID={`filterID${index}`} />;
            })}
            <button type="button" className={cx(`resetButton`)} onClick={resetFunction}>
                {resetLabel[i18n.curr.langCode]}
            </button>
        </div>
    );
};

export default FilterGroup;
