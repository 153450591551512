import React, { useState } from "react";
import classNames from "classnames/bind";
import Image from "@v4/gatsby-theme-talend/src/components/Image/Image";
import Link from "@v4/gatsby-theme-talend/src/components/Link";
import Modal from "react-modal";
import * as partnerCardStyles from "./partnerListingCard.module.css";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import formatContentfulRichText from "@v4/talend/src/utils/formatContentfulRichText";
import { useI18n } from "@v4/utils/i18nContext";
import { partnerListingCardTranslations } from "../../../translations/partnerListingCard";

const cx = classNames.bind(partnerCardStyles);

const PartnerListingCard = ({
    logo,
    modalLogo,
    logoAltText,
    partnerAccreditations,
    partnerProficiencies,
    partnerProductProficiencies,
    partnerType,
    partnerLevel,
    url,
    description,
}) => {
    // Setup modal
    const [modalOpen, setModalOpen] = useState(false);
    const { i18n } = useI18n();
    const cardTranslations = partnerListingCardTranslations[i18n.curr.langCode];
    const partnerProf = partnerProficiencies ?? [];
    const partnerProdProf = partnerProductProficiencies ?? [];

    // Combine the two proficiency types to one list
    const proficiencies = partnerProf.concat(partnerProdProf);

    // Produce image element
    const ImageEl = ({ image, className, maxWidth }) => (
        <div className={className}>
            {image && (
                <Image
                    placeholderStyle={{ filter: `blur(10px)` }}
                    imgStyle={{
                        objectFit: "contain",
                        objectPosition: "center center",
                        maxWidth: "168px",
                        maxHeight: "86px",
                    }}
                    style={{ maxWidth: maxWidth }}
                    image={image}
                    alt={logoAltText}
                />
            )}
        </div>
    );

    // Produce main card markup.
    const level = partnerLevel?.[0];
    const type = partnerType?.[0];
    let partnerLvlClass = level && level.name ? level.name : "";

    // JP Hack
    if (level && level.name === "ゴールド") {
        partnerLvlClass = "Gold";
    }
    if (level && level.name === "プラチナ") {
        partnerLvlClass = "Platinum";
    }

    const contentEl = (
        <div className={cx("partnerListingCardContent")}>
            {level && <p className={cx(partnerLvlClass, `partnerLevel`)}>{level.name}</p>}
            {type && <p className={cx(`partnerType`)}>{type.name}</p>}
            {partnerAccreditations &&
                partnerAccreditations.map((accreditation, i) => (
                    <p key={i} className={cx(`partnerAccreditation`)}>
                        {accreditation.name}
                    </p>
                ))}
        </div>
    );

    // Produce Overlay.
    const overlayEl = (
        <div className={cx("partnerCardCurtain", { partnerCardCurtainModalOpen: modalOpen })}>
            <span onClick={() => setModalOpen(true)} className={cx(`partnerArrowLink`)}>
                {cardTranslations.partnershipLink}
            </span>
            {url ? (
                <Link to={url} className={cx(`partnerArrowLink`)}>
                    {cardTranslations.visitLink}
                </Link>
            ) : null}
        </div>
    );

    // Partner details modal.
    Modal.setAppElement("#___gatsby");

    const modal = (
        <Modal
            className={cx("partnerModal")}
            overlayClassName={cx("partnerModalOverlay")}
            isOpen={modalOpen}
            closeTimeoutMS={320}
            onRequestClose={() => setModalOpen(false)}
        >
            <button onClick={() => setModalOpen(false)}>×</button>
            <section className={cx("partnerModalColumnLeft")}>
                {modalLogo?.[0] && <ImageEl maxWidth={"200px"} image={modalLogo?.[0]} />}
                <div className={cx("partnerModalContent")}>
                    {description ? renderRichText(description, formatContentfulRichText()) : null}
                </div>
                <Link to={url} className={cx(`partnerArrowLink`)}>
                    {cardTranslations.visitLink}
                </Link>
            </section>
            <section className={cx("partnerModalColumnRight")}>
                {level && <p className={cx(partnerLvlClass, `partnerLevel`)}>{level.name}</p>}
                {type && <p className={cx(`partnerType`)}>{type.name}</p>}

                {partnerAccreditations && <h5>{cardTranslations.accreditationsHeader}</h5>}
                <div className={cx("partnerModalBadges")}>
                    {partnerAccreditations &&
                        partnerAccreditations.map((accreditation, i) => (
                            <div key={i} className={cx(``)}>
                                {accreditation.image?.[0] && (
                                    <Image
                                        className={cx("")}
                                        placeholderStyle={{ filter: `blur(10px)` }}
                                        image={accreditation.image[0]}
                                        alt={accreditation.imageAltText}
                                    />
                                )}
                            </div>
                        ))}
                </div>
                {Boolean(proficiencies.length) && (
                    <>
                        <h5>{cardTranslations.proficienciesHeader}</h5>
                        <ul className={cx("partnerProficiencies")}>
                            {proficiencies.map((el, i) => (
                                <li key={i}>{el.name}</li>
                            ))}
                        </ul>
                    </>
                )}
            </section>
        </Modal>
    );

    return (
        <div className={cx(`partnerListing`)}>
            {logo?.[0] && <ImageEl maxWidth={"168px"} image={logo?.[0]} className={cx("partnerCardImage")} />}
            {contentEl}
            {overlayEl}
            {modal}
        </div>
    );
};

export default PartnerListingCard;
