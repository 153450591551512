export const sitemapTranslations = {
    en: {
        "Sitemap: About Talend": "About Talend",
        "Sitemap: News and Events": "News and events",
        "Sitemap: Legal": "Legal",
        "Sitemap: Legal: Privacy Notices": "Privacy notices",
        "Sitemap: Legal: Terms and Conditions": "Terms and conditions",
        "Sitemap: Legal: Product and Access": "Product and access",
        "Sitemap: Products": "Products",
        "Sitemap: Products: Categories": "Categories",
        "Sitemap: Products: Capabilities": "Capabilities",
        "Sitemap: Products: Contacts": "Contacts",
        "Sitemap: Products: Downloads": "Downloads",
        "Sitemap: Pricing and Packaging": "Pricing and packaging",
        "Sitemap: Pricing and Packaging: Contacts": "Contacts",
        "Sitemap: Solutions": "Solutions",
        "Sitemap: Solutions: Industries": "Industries",
        "Sitemap: Solutions: Lines of Business": "Lines of business",
        "Sitemap: Solutions: Initiatives": "Initiatives",
        "Sitemap: Partners": "Partners",
        "Sitemap: Partners: Technology Partners": "Technology partners",
        "Sitemap: Customers": "Customers",
        "Sitemap: Support and Services": "Support and services",
        "Sitemap: Accounts and Logins": "Accounts and logins",
        "Sitemap: Learning": "Learning",
        "Sitemap: Learning: Training and Classes": "Training and classes",
        "Sitemap: Learning: Resources": "Resources",
    },
    "en-GB": {
        "Sitemap: About Talend": "About Talend",
        "Sitemap: News and Events": "News and events",
        "Sitemap: Legal": "Legal",
        "Sitemap: Legal: Privacy Notices": "Privacy notices",
        "Sitemap: Legal: Terms and Conditions": "Terms and conditions",
        "Sitemap: Legal: Product and Access": "Product and access",
        "Sitemap: Products": "Products",
        "Sitemap: Products: Categories": "Categories",
        "Sitemap: Products: Capabilities": "Capabilities",
        "Sitemap: Products: Contacts": "Contacts",
        "Sitemap: Products: Downloads": "Downloads",
        "Sitemap: Pricing and Packaging": "Pricing and packaging",
        "Sitemap: Pricing and Packaging: Contacts": "Contacts",
        "Sitemap: Solutions": "Solutions",
        "Sitemap: Solutions: Industries": "Industries",
        "Sitemap: Solutions: Lines of Business": "Lines of business",
        "Sitemap: Solutions: Initiatives": "Initiatives",
        "Sitemap: Partners": "Partners",
        "Sitemap: Partners: Technology Partners": "Technology partners",
        "Sitemap: Customers": "Customers",
        "Sitemap: Support and Services": "Support and services",
        "Sitemap: Accounts and Logins": "Accounts and logins",
        "Sitemap: Learning": "Learning",
        "Sitemap: Learning: Training and Classes": "Training and classes",
        "Sitemap: Learning: Resources": "Resources",
    },
    fr: {
        "Sitemap: About Talend": "À propos de Talend",
        "Sitemap: News and Events": "Actualités et événements",
        "Sitemap: Legal": "Mentions légales",
        "Sitemap: Legal: Privacy Notices": "Politique de confidentialité",
        "Sitemap: Legal: Terms and Conditions": "Conditions générales",
        "Sitemap: Legal: Product and Access": "Produit et accès",
        "Sitemap: Products": "Produits",
        "Sitemap: Products: Categories": "Catégories",
        "Sitemap: Products: Capabilities": "Fonctionnalités",
        "Sitemap: Products: Contacts": "Contacts",
        "Sitemap: Products: Downloads": "Téléchargements",
        "Sitemap: Pricing and Packaging": "Tarifs et packaging",
        "Sitemap: Pricing and Packaging: Contacts": "Contacts",
        "Sitemap: Solutions": "Solutions",
        "Sitemap: Solutions: Industries": "Industries",
        "Sitemap: Solutions: Lines of Business": "Départements",
        "Sitemap: Solutions: Initiatives": "Initiatives",
        "Sitemap: Partners": "Partenaires",
        "Sitemap: Partners: Technology Partners": "Partenaires technologiques",
        "Sitemap: Customers": "Clients",
        "Sitemap: Support and Services": "Support et services",
        "Sitemap: Accounts and Logins": "Comptes et connexions",
        "Sitemap: Learning": "Apprentissage",
        "Sitemap: Learning: Training and Classes": "Formation et cours",
        "Sitemap: Learning: Resources": "Ressources",
    },
    de: {
        "Sitemap: About Talend": "Über Talend",
        "Sitemap: News and Events": "Neuigkeiten und Veranstaltungen",
        "Sitemap: Legal": "Rechtliche Hinweise",
        "Sitemap: Legal: Privacy Notices": "Datenschutzerklärungen",
        "Sitemap: Legal: Terms and Conditions": "Allgemeine Geschäftsbedingungen",
        "Sitemap: Legal: Product and Access": "Produkt und Zugang",
        "Sitemap: Products": "Produkte",
        "Sitemap: Products: Categories": "Kategorien",
        "Sitemap: Products: Capabilities": "Funktionen",
        "Sitemap: Products: Contacts": "Kontakte",
        "Sitemap: Products: Downloads": "Downloads",
        "Sitemap: Pricing and Packaging": "Preise und Pakete",
        "Sitemap: Pricing and Packaging: Contacts": "Kontakte",
        "Sitemap: Solutions": "Lösungen",
        "Sitemap: Solutions: Industries": "Branchen",
        "Sitemap: Solutions: Lines of Business": "Unternehmensbereiche",
        "Sitemap: Solutions: Initiatives": "Geschäftsinitiativen",
        "Sitemap: Partners": "Partner",
        "Sitemap: Partners: Technology Partners": "Technologiepartner",
        "Sitemap: Customers": "Kunden",
        "Sitemap: Support and Services": "Support und Services",
        "Sitemap: Accounts and Logins": "Konten und Anmeldungen",
        "Sitemap: Learning": "Lernen",
        "Sitemap: Learning: Training and Classes": "Schulungen und Kurse",
        "Sitemap: Learning: Resources": "Ressourcen",
    },
    it: {
        "Sitemap: About Talend": "A proposito di Talend",
        "Sitemap: News and Events": "Notizie ed eventi",
        "Sitemap: Legal": "Legale",
        "Sitemap: Legal: Privacy Notices": "Informazioni sulla privacy",
        "Sitemap: Legal: Terms and Conditions": "Termini e condizioni",
        "Sitemap: Legal: Product and Access": "Prodotto e accesso",
        "Sitemap: Products": "Prodotti",
        "Sitemap: Products: Categories": "Categorie",
        "Sitemap: Products: Capabilities": "Funzionalità",
        "Sitemap: Products: Contacts": "Contatti",
        "Sitemap: Products: Downloads": "Download",
        "Sitemap: Pricing and Packaging": "Prezzi e pacchetti",
        "Sitemap: Pricing and Packaging: Contacts": "Contatti",
        "Sitemap: Solutions": "Soluzioni",
        "Sitemap: Solutions: Industries": "Settori",
        "Sitemap: Solutions: Lines of Business": "Linee di business",
        "Sitemap: Solutions: Initiatives": "Iniziative",
        "Sitemap: Partners": "Partner",
        "Sitemap: Partners: Technology Partners": "Partner tecnologici",
        "Sitemap: Customers": "Clienti",
        "Sitemap: Support and Services": "Supporto e servizi",
        "Sitemap: Accounts and Logins": "Account e login",
        "Sitemap: Learning": "Imparare",
        "Sitemap: Learning: Training and Classes": "Formazione e lezioni",
        "Sitemap: Learning: Resources": "Risorse",
    },
    ja: {
        "Sitemap: About Talend": "Talendについて",
        "Sitemap: News and Events": "ニュースとイベント",
        "Sitemap: Legal": "リーガル",
        "Sitemap: Legal: Privacy Notices": "プライバシーに関する通知",
        "Sitemap: Legal: Terms and Conditions": "規約および条件",
        "Sitemap: Legal: Product and Access": "製品ライセンスについて",
        "Sitemap: Products": "製品",
        "Sitemap: Products: Categories": "カテゴリー",
        "Sitemap: Products: Capabilities": "機能",
        "Sitemap: Products: Contacts": "お問い合わせ",
        "Sitemap: Products: Downloads": "ダウンロード",
        "Sitemap: Pricing and Packaging": "価格とパッケージング",
        "Sitemap: Pricing and Packaging: Contacts": "お問い合わせ",
        "Sitemap: Solutions": "ソリューション",
        "Sitemap: Solutions: Industries": "業界",
        "Sitemap: Solutions: Lines of Business": "部門",
        "Sitemap: Solutions: Initiatives": "取り組み",
        "Sitemap: Partners": "パートナー",
        "Sitemap: Partners: Technology Partners": "テクノロジーパートナー",
        "Sitemap: Customers": "カスタマー",
        "Sitemap: Support and Services": "サポートとサービス",
        "Sitemap: Accounts and Logins": "アカウントとログイン",
        "Sitemap: Learning": "ラーニング",
        "Sitemap: Learning: Training and Classes": "トレーニング",
        "Sitemap: Learning: Resources": "リソース",
    },
};
