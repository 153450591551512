import React from "react";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import stylingOptions from "@v4/utils/stylingOptions";

const ContentListing = ({ cx, formatContentfulRichText, listingTypeMap, ...props }) => {
    const styleOpts = stylingOptions(props.stylingOptions, cx);

    // Text shade options for cx classes:
    const hShade = props.headingTextShade ? "textShade" + props.headingTextShade : null;

    // Set up rich-text content
    const heading = props.heading && props.heading.raw ? renderRichText(props.heading, formatContentfulRichText()) : "";
    const featuredHeading =
        props.featuredHeading && props.featuredHeading.raw
            ? renderRichText(props.featuredHeading, formatContentfulRichText())
            : "";

    const featuredItems = props.featuredItems ?? [];
    const tierOneItems = props.tierOneItems ?? [];
    const itemsPerPage = props.itemsPerPage ?? 9;

    // Get type of listing
    const listType = props.listContentType ? props.listContentType : "Customers";
    const Listing = listingTypeMap[listType];

    return (
        <section id={props.moduleId} className={styleOpts.classString} style={styleOpts.bgHex}>
            {styleOpts && styleOpts.borderTopEl}
            <div className={`${cx("listingContainer", hShade)}`}>
                <Listing
                    listType={listType}
                    title={heading}
                    featuredHeading={featuredHeading}
                    featuredItems={featuredItems}
                    tierOneItems={tierOneItems}
                    itemsPerPage={itemsPerPage}
                    locale={props.node_locale}
                />
            </div>
            {styleOpts && styleOpts.bgImageEl}
            {styleOpts && styleOpts.borderBottomEl}
        </section>
    );
};

export default ContentListing;
