import React, { useEffect, useState, useRef, useCallback, useMemo } from "react";
import { graphql } from "gatsby";
import { useI18n } from "@v4/utils/i18nContext";
import scrollWithHeaderOffset from "@v4/utils/scrollWithHeaderOffset";
import { withDefault, NumberParam } from "use-query-params";
import { withQueryParamProvider } from "@v4/utils/withQueryParamProvider";
import { PipeArrayDelimiter, getTermSubTerms, getFilterTaxonomyTree, useQueryParams } from "@v4/utils/filterUtils";

// Resource fragment is used in static query below.
export const fragment = graphql`
    fragment Resource on ContentfulResource {
        id
        contentful_id
        node_locale
        title
        createdAt
        sortDate
        languages
        featuredImage {
            # fluid (maxWidth: 800, transformations: "f_auto,q_auto,c_crop,g_center,ar_1.8")
            public_id
            width
            height
            format
        }
        featuredImageAltText
        summary
        resourceType {
            name
            contentful_id
        }
        resourceTypeSpecific {
            name
            contentful_id
        }
        resourceTopic {
            name
            contentful_id
        }
        pageLink {
            ...PageLink
        }
        url
    }
`;

// A filterable listing of all resources in the current language. Featured items are shown as
// cards along the top, while "tier 1" items show up at the top of the rest of the resources
// in the listing.
const ResourceListing = ({
    cx,
    title,
    featuredHeading,
    featuredItems,
    tierOneItems,
    itemsPerPage,
    commonTranslations,
    FilterGroup,
    ResourceFeaturedCard,
    Resource,
    showNumResults,
    data,
}) => {
    const { i18n } = useI18n();
    const translations = commonTranslations[i18n.curr.langCode];

    // Filter state.
    const [allParams, setAllParams] = useQueryParams({
        type: withDefault(PipeArrayDelimiter, []),
        topic: withDefault(PipeArrayDelimiter, []),
        page: withDefault(NumberParam, 1),
    });

    // The list of resources that match the current filters.
    const [resources, setResources] = useState([]);

    // Pagination state.
    const [hasMore, setHasMore] = useState(false);

    // Flags to determine whether we should filter.
    const shouldFilterByResourceType = allParams?.type?.length;
    const shouldFilterByResourceTopic = allParams?.topic?.length;

    // Get relevant taxonomy terms (Customers in the current language).
    const filterTaxonomyTree = getFilterTaxonomyTree(data.resourceTaxonomy, i18n.curr.langCode, "Resources");

    // Resource Type and Topic lists.
    const resourceTypes = getTermSubTerms(filterTaxonomyTree?.[0]?.name, filterTaxonomyTree);
    const resourceTopics = getTermSubTerms(filterTaxonomyTree?.[1]?.name, filterTaxonomyTree);

    // Resource Topic translations.
    const resourceTypeTranslations = data.resourceTaxonomy?.nodes.map((tree) => ({
        node_locale: tree.node_locale,
        terms: tree?.terms?.[0]?.subTerms,
    }));

    // Cards will have different gradients and button text per resource type.
    const getGradientTypeAndButtonText = (resource) => {
        const resourceType = resource?.resourceType;

        // Get the english resource type.
        const enResourceType = resourceTypeTranslations
            ?.find((el) => el.node_locale === "en")
            ?.terms?.find((el) => el.contentful_id === resourceType?.[0]?.contentful_id);

        return (
            {
                "Connect on-demand": { gradientClass: "webinarConnectGradient", buttonText: translations.watchNow },
                Videos: { gradientClass: "videoGradient", buttonText: translations.watchNow },
                "White papers and ebooks": { gradientClass: "ebookGradient", buttonText: translations.downloadNow },
                "On-demand webinars": { gradientClass: "webinarGradient", buttonText: translations.watchNow },
                "Analyst reports": { gradientClass: "reportGradient", buttonText: translations.downloadNow },
                Podcasts: { gradientClass: "videoGradient", buttonText: translations.listenNow },
            }[enResourceType?.name] ?? {}
        );
    };

    // All non-featured resources should be handled by the listing/filtering features.
    const allListingResources = useMemo(() => {
        // Grab the ids from tier one items so that we can remove them from the remaining list of resources.
        const tierOneIds = tierOneItems.map((item) => item.contentful_id);

        // Filter out tier one resources so they can be prepended to the list.
        // Sort them by createdAt DESC.
        const nonTierOneResources = data.resources.nodes
            .filter((resource) => resource.node_locale === i18n.curr.langCode)
            .filter((resource) => !tierOneIds.includes(resource.contentful_id))
            .sort((a, b) => {
                // if sortDate field is available, use it instead of createdAt field
                const sortDateA = a.sortDate ? new Date(a.sortDate) : new Date(a.createdAt);
                const sortDateB = b.sortDate ? new Date(b.sortDate) : new Date(b.createdAt);
                return sortDateB - sortDateA;
            });

        return tierOneItems
            .concat(nonTierOneResources)
            .filter((resource) => ["All", i18n.curr.enLangName].some((lang) => resource.languages?.includes(lang)));
    }, [i18n.curr.enLangName, data.resources.nodes, i18n.curr.langCode, tierOneItems]);

    // The list of currently visible resources depending on pagination.
    const [list, setList] = useState([]);

    const handleFiltering = useCallback(
        (data) => {
            let filteredResources = data;

            if (shouldFilterByResourceType) {
                filteredResources = filteredResources.filter(
                    (resource) =>
                        resource.resourceType &&
                        resource.resourceType.some((term) => allParams?.type.includes(term.name))
                );
            }

            if (shouldFilterByResourceTopic) {
                filteredResources = filteredResources.filter(
                    (resource) =>
                        resource.resourceTopic &&
                        resource.resourceTopic.some((term) => allParams?.topic.includes(term.name))
                );
            }

            setResources(filteredResources);
            setList([...filteredResources.slice(0, allParams?.page * itemsPerPage)]);
        },
        [shouldFilterByResourceTopic, shouldFilterByResourceType, itemsPerPage, allParams]
    );

    // Check if there are more resources to show.
    useEffect(() => {
        const isMore = list.length < resources.length;
        setHasMore(isMore);
    }, [list, resources]);

    useEffect(() => {
        handleFiltering(allListingResources);
    }, [allListingResources, handleFiltering]);

    // Filter config to pass to FilterGroup component below.
    const filters = filterTaxonomyTree && [
        {
            title: filterTaxonomyTree?.[0]?.name,
            allTerms: resourceTypes,
            currentValues: allParams && allParams?.type,
            setter: (checkedBoxes) => {
                setAllParams({ ...allParams, type: checkedBoxes });
            },
            open: allParams?.type?.length > 0,
            name: "type",
            filterType: "Checkbox",
        },
        {
            title: filterTaxonomyTree?.[1]?.name,
            allTerms: resourceTopics,
            currentValues: allParams?.topic,
            setter: (checkedBoxes) => {
                setAllParams({ ...allParams, topic: checkedBoxes });
            },
            open: allParams?.topic?.length > 0,
            name: "topic",
            filterType: "Checkbox",
        },
    ];

    const resetFilters = () => {
        setAllParams(
            {
                type: [],
                topic: [],
                page: 1,
            },
            "replaceIn"
        );
    };

    const NoResults = () => {
        return (
            <div className="pt-3">
                <h3>There are currently no resources matching your criteria.</h3>
            </div>
        );
    };

    // Create ref for "return to top" feature.
    const topRef = useRef(null);
    const scrollToTop = () => scrollWithHeaderOffset(topRef.current);

    // Handle "View more" button click
    const handleLoadMore = () => {
        if (hasMore) {
            const currentLength = list.length;
            const isMore = currentLength < resources.length;
            const nextResults = isMore ? resources.slice(currentLength, currentLength + itemsPerPage) : [];
            setList([...list, ...nextResults]);
            setAllParams({
                ...allParams,
                page: allParams.page + 1,
            });
        }
    };

    const ResourcesListingWrapper = ({ resources }) => {
        if (!resources.length) return <NoResults />;
        return (
            <>
                <div
                    className={cx(
                        "resourcesListingWrapper",
                        "grid",
                        "grid-cols-1",
                        "gap-x-3",
                        "gap-y-7",
                        "md:grid-cols-2",
                        "lg:grid-cols-3"
                    )}
                >
                    {resources.map((resource, index) => {
                        const { gradientClass, buttonText } = getGradientTypeAndButtonText(resource);
                        return (
                            <Resource
                                key={`resource-${index}`}
                                {...resource}
                                gradientClass={gradientClass}
                                buttonText={buttonText}
                            />
                        );
                    })}
                </div>
                <div className="flex justify-center py-8 gap-4">
                    {hasMore && (
                        <button className={cx(`btn`, `uiButton`)} onClick={handleLoadMore}>
                            {translations.viewMore}
                        </button>
                    )}
                    {allParams.page > 1 && (
                        <button className={cx(`btn`, `uiButton`)} onClick={scrollToTop}>
                            {translations.toTop}
                        </button>
                    )}
                </div>
            </>
        );
    };

    const FeaturedResources = ({ featuredItems }) => (
        <div className="grid gap-2 auto-rows-auto mb-6 md:grid-cols-3">
            {featuredItems.map((item, i) => {
                const { buttonText } = getGradientTypeAndButtonText(item);
                return <ResourceFeaturedCard key={i} {...item} buttonText={buttonText} />;
            })}
        </div>
    );

    const featured = featuredItems.filter((resource) =>
        ["All", i18n.curr.enLangName].some((lang) => resource.languages?.includes(lang))
    );

    return (
        <>
            {featuredHeading && (
                <div ref={topRef} className="mb-6">
                    {featuredHeading}
                </div>
            )}
            {featured?.length ? <FeaturedResources featuredItems={featured} /> : null}
            {title && <div className="mb-6">{title}</div>}
            <div className="flex w-full relative flex-col md:flex-row">
                {filters && <FilterGroup filters={filters} resetFunction={resetFilters} />}

                <div className="w-full">
                    {showNumResults && (
                        <div
                            className={cx(
                                "grid",
                                "grid-cols-3",
                                "gap-x-3",
                                "border-b",
                                "border-c_michael_gray",
                                "mb-4"
                            )}
                        >
                            <div
                                className={cx(
                                    "col-start-3",
                                    "px-4",
                                    "py-1",
                                    "border-c_michael_gray",
                                    "border-l",
                                    "text-c_stone_gray"
                                )}
                            >
                                {resources?.length} Results
                            </div>
                        </div>
                    )}
                    <ResourcesListingWrapper resources={list} />
                </div>
            </div>
        </>
    );
};

export default withQueryParamProvider(ResourceListing);
