export const partnerListingCardTranslations = {
    en: {
        partnershipLink: "About our partnership",
        visitLink: "Visit this partner",
        accreditationsHeader: "Accreditations",
        proficienciesHeader: "Partner Proficiencies",
    },
    "en-GB": {
        partnershipLink: "About our partnership",
        visitLink: "Visit this partner",
        accreditationsHeader: "Accreditations",
        proficienciesHeader: "Partner Proficiencies",
    },
    fr: {
        partnershipLink: "À propos de notre partenariat",
        visitLink: "Visitez ce partenaire",
        accreditationsHeader: "Accréditations",
        proficienciesHeader: "Compétences",
    },
    de: {
        partnershipLink: "Über unsere Partnerschaft",
        visitLink: "Besuchen Sie diesen Partner",
        accreditationsHeader: "Akkreditierungen",
        proficienciesHeader: "Partnerkompetenz",
    },
    it: {
        partnershipLink: "Informazioni sulla nostra partnership",
        visitLink: "Visita questo partner",
        accreditationsHeader: "Certificazioni",
        proficienciesHeader: "Competenze",
    },
    ja: {
        partnershipLink: "当社のパートナーシップについて",
        visitLink: "このパートナーを訪問",
        accreditationsHeader: "認定",
        proficienciesHeader: "専門性",
    },
};
