import { encodeDelimitedArray, decodeDelimitedArray, useQueryParams as tempUseQueryParams } from "use-query-params";

// Get the sub terms for one of the top level terms of the taxonomy tree.
export const getTermSubTerms = (termName, filterTaxonomyTree, nameOnly = true) => {
    const term = filterTaxonomyTree?.filter((term) => term.name === termName)?.[0];
    return term?.subTerms.map((term) => (nameOnly ? term.name : term));
};

export const getFilterTaxonomyTree = (data, locale, taxonomyName) => {
    // Get relevant taxonomy terms.
    return data?.nodes?.filter((taxonomy) => taxonomy.node_locale === locale && taxonomy.name === taxonomyName)[0]
        ?.terms;
};

/** For use with use-query-params... It Uses a pipe to delimit entries. e.g. ['a', 'b'] => qp?=a|b */
export const PipeArrayDelimiter = {
    encode: (array) => (array.length > 0 ? encodeDelimitedArray(array, "|") : undefined),
    decode: (arrayStr) => decodeDelimitedArray(arrayStr, "|"),
};

// A version of useQueryParams where the update type can be set on the hook level.
export const useQueryParams = (name, paramConfig, updateType = "replaceIn") => {
    const [value, setter] = tempUseQueryParams(name, paramConfig);
    return [value, (state) => setter(state, updateType)];
};
