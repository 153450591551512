import React from "react";
import Image from "@v4/gatsby-theme-talend/src/components/Image/Image";
import Link from "@v4/gatsby-theme-talend/src/components/Link";
import { getPrefixedPathByPageType } from "@v4/utils/pathPrefix";

import classNames from "classnames/bind";
import * as styles from "./singleWebinarItem.module.css";
import truncate from "@v4/utils/truncate";

const cx = classNames.bind(styles);

const SingleWebinarItem = ({
    title,
    summary,
    featuredImage,
    featuredImageAltText,
    buttonText,
    pageLink,
    url,
    presentation = "",
    resourceTypeSpecific,
}) => {
    const path = pageLink ? getPrefixedPathByPageType(pageLink.slug, pageLink.__typename) : url;
    // 'webinarCardClassName' will be either 'webinarItemFeaturedCard' or 'webinarItemCard'
    // This will apply different styling to Featured cards and normal (Tier One) cards
    const webinarCardClassName = `webinarItem${presentation}Card`;

    return presentation === "" ? (
        /**
         * Normal (Tier One) cards
         * Markup is taken from ./src/components/resources/resources.js - lines 23 - 39
         * Should be similar except for changes to classNames used and also:
         *    1. Instead of using the 'resourceType' term in the top bar of the card, we're now using 'resourceTypeSpecific'.
         *    2. We're then also removing the grey text below the top bar that used to show the 'resourceTypeSpecific' term text.
         */
        <Link to={path} className={cx(webinarCardClassName)}>
            <div className="flex flex-col flex-1 flex-grow h-full rounded-md shadow-lg">
                <div className={cx("rounded-t-md", "p-2", "text-lead", "text-white", "webinarConnectGradient")}>
                    {resourceTypeSpecific[0]?.name}
                </div>
                <div className="cardBody flex flex-1 h-full flex-col py-3 px-2 text-base">
                    {title && <h4 className="text-h3 mb-2 font-semibold">{truncate(title, 50)}</h4>}
                    {summary && <p className="mt-0">{truncate(summary, 150)}</p>}
                </div>
                <div className="pb-3 px-2 text-lead">
                    <button className={cx("mt-auto", " self-start", "webinarItemCardButton")}>{buttonText}</button>
                </div>
            </div>
        </Link>
    ) : (
        /**
         * Featured cards
         * Markup is taken from ./src/components/resources/resourceFeaturedCard.js - lines 25 - 45
         * Should be similar except for changes to classNames used and also:
         *    1. We're removing the grey text below the featured image that used to show the 'resourceTypeSpecific' term text.
         */
        <Link to={path} className={cx(webinarCardClassName, "max-w-lg", "mx-auto", "flex", "flex-col", "rounded-md")}>
            {featuredImage?.[0] ? (
                <Image
                    className="rounded-t-md mb-2"
                    placeholderStyle={{ filter: `blur(10px)` }}
                    image={featuredImage?.[0]}
                    alt={featuredImageAltText}
                />
            ) : (
                <img
                    className="rounded-t-md"
                    src="https://res.cloudinary.com/talend/image/upload/f_auto,q_auto,c_crop,g_center,w_800,ar_1.8/resources%2Ffeature-resources-asset-cover_diutil.jpg"
                    alt="Featured resource"
                />
            )}
            <div className="pt-1 pr-3 pb-3 pl-3 flex-grow flex flex-col">
                <h4 className="text-c_text_grey mb-6 text-lead font-semibold">{title}</h4>
                <button className={cx("mt-auto", "self-start", "webinarFeaturedCardButton")}>{buttonText}</button>
            </div>
        </Link>
    );
};

export default SingleWebinarItem;
