import React, { useEffect, useState } from "react";
import classnames from "classnames/bind";
import axios from "axios";
import Link from "@v4/gatsby-theme-talend/src/components/Link";
import { useI18n } from "@v4/utils/i18nContext";
import { commonTranslations } from "@v4/gatsby-theme-talend/src/translations/common";
import * as postListStyles from "./blogCategory.module.css";

const cx = classnames.bind(postListStyles);
const { getLangPrefixedPath } = require("@v4/utils/pathPrefix");

const BlogCategory = ({ title, categoryId = "1Ygw7mZnGORXgoR3Okt6rY", locale, itemsPerPage = 10 }) => {
    const { i18n } = useI18n();
    const blogCategoryEndpoint = "/.netlify/functions/blogCategory";
    const [categoryData, setCategoryData] = useState([]);
    const limit = itemsPerPage;

    useEffect(() => {
        getBlogCategory(blogCategoryEndpoint, categoryId, locale, limit);
    }, [blogCategoryEndpoint, categoryId, locale, limit]);

    // Function to fetch blog posts of a category from Contentful's API
    const getBlogCategory = (blogCategoryEndpoint, categoryId, locale, limit) => {
        // Variables to be passed to the endpoint (query parameters)
        const data = { categoryId, locale, limit };

        // Axios "get" to the endpoint w/query params
        axios
            .get(blogCategoryEndpoint, {
                params: {
                    data,
                },
            })
            .then((response) => {
                // Set the response data to the state
                setCategoryData(response.data);
            })
            .catch((err) => {
                /* eslint-disable no-console */
                console.error(JSON.stringify(err, null, 2));
            });
    };

    const BlogPost = ({ title, previewText, slug, image, publishDate, locale, metaFields }) => {
        const pageUrl = getLangPrefixedPath(`/blog/${slug}`, locale);
        const lang = i18n.get("langName").from("langCode", locale);

        // For fetching both the blog main image and meta image
        const mainImage = image && image[0] ? image[0].secure_url : false;
        const metaImage = metaFields?.metaImage && metaFields.metaImage[0] ? metaFields.metaImage[0].secure_url : false;
        const postThumbnail = mainImage || metaImage;

        return (
            <li className={cx(`blogListItem`)}>
                {postThumbnail && (
                    <div className={cx(`blogthumbnail`)}>
                        <img className={cx(`blogthumbnailImage`)} src={postThumbnail} alt={title} />
                    </div>
                )}
                <div className={cx(`blogPostContent`)}>
                    <p className={cx(`postDate`)}>{i18n.getLocalisedDate(publishDate, null, lang)}</p>
                    <h3 className={cx(`postTitle`)}>{title}</h3>
                    <p className={cx(`postExcerpt`)}>{previewText}</p>
                    <Link to={pageUrl} className={cx(`postLink`)}>
                        {commonTranslations[locale].readMore}
                    </Link>
                </div>
            </li>
        );
    };

    return (
        <>
            {Array.isArray(categoryData) ? (
                <>
                    {title}
                    <ul className={cx("contentHolder", `list`)}>
                        {categoryData.map(({ fields }, index) => {
                            return (
                                <BlogPost
                                    key={`item-${index}`}
                                    publishDate={fields?.publishDate}
                                    previewText={fields?.previewText}
                                    slug={fields?.slug}
                                    image={fields?.image || fields?.metaFields?.fields?.metaImage}
                                    title={fields?.title}
                                    locale={locale}
                                    metaFields={fields?.metaFields}
                                />
                            );
                        })}
                    </ul>
                </>
            ) : null}
        </>
    );
};

export default BlogCategory;
