import React from "react";
import classnames from "classnames/bind";
import FilterCheckboxTree from "./FilterCheckboxTree";
import * as checkboxFilterStyles from "./checkboxFilter.module.css";
import BaseFilterCheckboxes from "@v4/gatsby-theme-talend/src/components/filters/FilterCheckboxes";

// cx for CSS Modules
const cx = classnames.bind(checkboxFilterStyles);

const FilterCheckboxes = (props) => <BaseFilterCheckboxes cx={cx} FilterCheckboxTree={FilterCheckboxTree} {...props} />;

export default FilterCheckboxes;
