import React from "react";
import classnames from "classnames/bind";
import FilterCheckboxes from "./FilterCheckboxes";
import FilterDropdown from "./FilterDropdown";
import FilterSearch from "./FilterSearch";
import BaseFilterGroup from "@v4/gatsby-theme-talend/src/components/filters/FilterGroup";
import * as filterGroupStyles from "./filterGroup.module.css";

// cx for CSS Modules
const cx = classnames.bind(filterGroupStyles);

// Map of filter type to FilterGroup component.
const filterTypeMap = {
    Select: FilterDropdown,
    Checkbox: FilterCheckboxes,
    Search: FilterSearch,
};

const FilterGroup = (props) => <BaseFilterGroup cx={cx} filterTypeMap={filterTypeMap} {...props} />;

export default FilterGroup;
