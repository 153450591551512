import { commonTranslations as common } from "@v4/gatsby-theme-talend/src/translations/common";

export const eventListingTranslations = {
    en: {
        register: "Register",
        watch: common.en.watchNow,
        viewMore: common.en.viewMore,
        toTop: common.en.toTop,
        noResultsMessage: "There are currently no events matching your criteria.",
    },
    "en-GB": {
        register: "Register",
        watch: common["en-GB"].watchNow,
        viewMore: common["en-GB"].viewMore,
        toTop: common["en-GB"].toTop,
        noResultsMessage: "There are currently no events matching your criteria.",
    },
    fr: {
        register: "S'inscrire",
        watch: common.fr.watchNow,
        viewMore: common.fr.viewMore,
        toTop: common.fr.toTop,
        noResultsMessage: "There are currently no events matching your criteria.",
    },
    de: {
        register: "Registrieren",
        watch: common.de.watchNow,
        viewMore: common.de.viewMore,
        toTop: common.de.toTop,
        noResultsMessage: "There are currently no events matching your criteria.",
    },
    it: {
        register: "Registrati",
        watch: common.it.watchNow,
        viewMore: common.it.viewMore,
        toTop: common.it.toTop,
        noResultsMessage: "There are currently no events matching your criteria.",
    },
    ja: {
        register: "登録",
        watch: common.ja.watchNow,
        viewMore: common.ja.viewMore,
        toTop: common.ja.toTop,
        noResultsMessage: "There are currently no events matching your criteria.",
    },
};
