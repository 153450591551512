import React, { useState, useEffect } from "react";
import FilterArrow from "../../assets/svg/filter-arrow.inline.svg";

const FilterCheckboxTree = ({ cx, FilterCheckbox, data = [], handler, currentValues, level = 0, groupName }) => {
    return (
        <div className={cx("tree")}>
            <ul className={cx("filterList")}>
                {data.map((tree, index) => (
                    <TreeNode
                        cx={cx}
                        FilterCheckbox={FilterCheckbox}
                        node={tree}
                        key={index}
                        handler={handler}
                        currentValues={currentValues}
                        level={level}
                        groupName={groupName}
                    />
                ))}
            </ul>
        </div>
    );
};

const TreeNode = ({ cx, FilterCheckbox, node, handler, currentValues, level, groupName }) => {
    const [childVisible, setChildVisiblity] = useState(false);
    const hasChild = node?.subTerms;
    const checkSomeSubTermsChecked = (arr, target) => target.some((v) => arr.includes(v.name));

    useEffect(() => {
        // leave the sub-menu open if some of the terms are in the current state.
        if (node.subTerms && checkSomeSubTermsChecked(currentValues, node.subTerms)) {
            setChildVisiblity(true);
        }
    }, [currentValues, node.subTerms]);

    return (
        <li className={cx("treeNode", `${childVisible && cx("isOpen")}`)} key={node?.contentful_id ?? node}>
            <FilterCheckbox
                handler={handler}
                currentValues={currentValues}
                level={level}
                node={node}
                groupName={groupName}
            />

            {hasChild && (
                <FilterArrow
                    onClick={(e) => setChildVisiblity((v) => !v)}
                    className={cx("filterArrowSymbol", `${childVisible ? {} : cx("isOpen")}`)}
                />
            )}

            {hasChild && childVisible && (
                <div className={cx("treeContent")}>
                    <ul className={cx("childFilterList")}>
                        <FilterCheckboxTree
                            cx={cx}
                            FilterCheckbox={FilterCheckbox}
                            data={node.subTerms}
                            handler={handler}
                            currentValues={currentValues}
                            level={level + 1}
                        />
                    </ul>
                </div>
            )}
        </li>
    );
};

export default FilterCheckboxTree;
