import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import classNames from "classnames/bind";
import { useI18n } from "@v4/utils/i18nContext";
import { sitemapTranslations } from "../../translations/sitemap";
import Link from "@v4/gatsby-theme-talend/src/components/Link";
import * as sitemapStyles from "./sitemap.module.css";

const cx = classNames.bind(sitemapStyles);

// The main component
const Sitemap = () => {
    const { i18n } = useI18n();
    const translations = sitemapTranslations[i18n.curr.langCode];

    const { sitemapPages, sitemapTags, sitemapLinks } = useStaticQuery(graphql`
        query SitemapQuery {
            sitemapPages: allContentfulPage(
                filter: { metadata: { tags: { elemMatch: { name: { regex: "/^Sitemap: /" } } } } }
                sort: { fields: title, order: ASC }
            ) {
                nodes {
                    metadata {
                        tags {
                            name
                        }
                    }
                    node_locale
                    languages
                    contentful_id
                    slug
                    title
                    name
                }
            }
            sitemapLinks: allContentfulSitemapLink(
                filter: { title: { nin: ["[[NoItem]]", null] } }
                sort: { fields: title, order: ASC }
            ) {
                nodes {
                    metadata {
                        tags {
                            name
                        }
                    }
                    node_locale
                    title
                    url
                }
            }
            sitemapTags: allContentfulTag(
                filter: { name: { regex: "/^Sitemap: /" } }
                sort: { fields: name, order: ASC }
            ) {
                nodes {
                    name
                }
            }
        }
    `);

    const tags = sitemapTags.nodes ?? [];
    const pages = sitemapPages.nodes ?? [];
    const links = sitemapLinks.nodes ?? [];

    // Get the pages in the current language
    const pagesInCurrentLanguage = pages.filter(
        (page) =>
            page.languages &&
            page.node_locale === i18n.curr.langCode &&
            (page.languages.includes("All") || page.languages.includes(i18n.curr.langName))
    );

    // Get the sitemap links in the current language
    const linksInCurrentLanguage = links.filter((link) => link.node_locale === i18n.curr.langCode);
    // Merge the pages and links to one list
    const sitemapContent = pagesInCurrentLanguage.concat(linksInCurrentLanguage);

    // Get the tag order from the translations file
    const tagOrder = Object.keys(translations);

    // Helper functions
    // Count occurrences of word or character
    const countOccurrences = (string, word) => {
        return string.split(word).length - 1;
    };

    // Group pages by tag
    const groupByTag = (array) => {
        return Object.values(
            array.reduce((all, curr) => {
                const key = curr.metadata.tags;
                (all[key] || (all[key] = [])).push(curr);
                return all;
            }, {})
        );
    };

    // Filter out the SubTags aka sub sections for the provided parent item
    const filterSubTags = (array, parentItem) => {
        return array
            .map((item) => {
                if (item.name.includes(parentItem.name + ": ")) {
                    const pageArray = pagesGroupedByTag.filter((page) =>
                        page.metadata.tags.some((tag) => tag.name === item.name)
                    );

                    return {
                        tag: item.name,
                        title: translations[item.name],
                        pages: pageArray.sort((a, b) => a.title.localeCompare(b.title)), // Sort pages in the section alpha
                    };
                } else {
                    return null;
                }
            })
            .filter((item) => item);
    };

    // Sort the tags by the tag order from above
    const sortedTags = tags.sort((a, b) => {
        return tagOrder.indexOf(a.name) - tagOrder.indexOf(b.name);
    });

    //Group pages and links by tag
    const pagesGroupedByTag = groupByTag(sitemapContent).flat(1);

    // Figure out the top level Sitemap sections and their sub sections and create a
    // traversable object we can render
    const sitemapSections = sortedTags
        .map((tag) => {
            const isParentTag = Boolean(countOccurrences(tag.name, ":") === 1);
            const subTags = filterSubTags(tags, tag);
            const pageArray = pagesGroupedByTag.filter((page) => page.metadata.tags.some((t) => t.name === tag.name));

            return {
                tag: tag.name,
                title: translations[tag.name],
                pages: pageArray.sort((a, b) => a.title.localeCompare(b.title)), // Sort pages in the section alpha
                subTags: subTags,
                isParentTag: isParentTag,
            };
        })
        .filter((item) => item.isParentTag);

    // Render a sitemap section, with sub sections if they exist
    const SitemapSection = ({ title, pages, subTags }) => {
        return (
            <div className={cx("sitemapSection", subTags.length > 0 ? "hasSubTags" : "")}>
                <SitemapList title={title} pages={pages} subList={false} />
                {subTags &&
                    subTags.length > 0 &&
                    subTags.map((subTag, index) => (
                        <SitemapList title={subTag.title} pages={subTag.pages} key={index} subList={true} />
                    ))}
            </div>
        );
    };

    // Render a sitemap column containing the provided sections
    const SitemapColumn = ({ columnSections, allSitemapSections }) => {
        const selectedSections = columnSections.map((section) => {
            return allSitemapSections[section];
        });

        return (
            <div className={cx(`sitemapColumn`)}>
                {selectedSections &&
                    selectedSections.map((section, index) => {
                        return (
                            <SitemapSection
                                title={section.title}
                                pages={section.pages}
                                subTags={section.subTags}
                                key={index}
                            />
                        );
                    })}
            </div>
        );
    };

    // Render the lists of links
    const SitemapList = ({ title, pages, subList = false }) => {
        return (
            <>
                {title && <h3 className={cx(subList ? "subHeading" : "")}>{title}</h3>}
                {pages && pages.length > 0 && (
                    <ul className={cx("sitemapList", subList ? "subList" : "")}>
                        {pages.map((page, index) => {
                            const url = page.slug ? page.slug : page.url;
                            return (
                                <li key={`sitemap-list-${index}`}>
                                    <Link to={url}>{page.title}</Link>
                                </li>
                            );
                        })}
                    </ul>
                )}
            </>
        );
    };

    // Currently There are 4 columns with sections in them. The order is based on the order
    // in the translation file. The columns will grow independently. So if one
    // section gets extremely long we can easily just swap the numbers around below.
    // Or add another column if required in the future.

    return (
        <section id={`sitemap`} className={`${cx("sitemapModule")}`}>
            <div className={cx(`container`)}>
                <SitemapColumn columnSections={[0, 1, 2]} allSitemapSections={sitemapSections} />
                <SitemapColumn columnSections={[3, 4]} allSitemapSections={sitemapSections} />
                <SitemapColumn columnSections={[5, 6, 7]} allSitemapSections={sitemapSections} />
                <SitemapColumn columnSections={[8, 9, 10]} allSitemapSections={sitemapSections} />
            </div>
        </section>
    );
};

export default Sitemap;
