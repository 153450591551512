import React from "react";
import classNames from "classnames/bind";
import truncate from "@v4/utils/truncate";
import CTA from "@v4/talend/src/components/cta/Cta";

import * as eventCardStyles from "./eventCard.module.css";

const cx = classNames.bind(eventCardStyles);

const EventCard = ({
    category,
    gradientClassName,
    availability,
    location,
    cardTitle,
    cardDesc,
    ctaUrl,
    ctaPageLink,
    ctaText,
    langCode,
}) => {
    // Setup card description
    return (
        <div className={cx("eventCard")}>
            {category && <div className={cx("cardHeader", gradientClassName)}>{category}</div>}
            <div className={cx("cardBody")}>
                {(availability || location) && (
                    <div className={cx("cardDate")}>
                        {availability}
                        {location && (
                            <>
                                <br />
                                {location}
                            </>
                        )}
                    </div>
                )}
                {cardTitle && <div className={cx("cardTitle")}>{truncate(cardTitle, 50)}</div>}
                {cardDesc && <div className={cx("cardDesc")}>{truncate(cardDesc, 150)}</div>}
            </div>
            <div className={cx("cardFooter")}>
                <CTA title={ctaText} url={ctaUrl} pageLink={ctaPageLink} variant="Tertiary" language={langCode} />
            </div>
        </div>
    );
};

export default EventCard;
