import React from "react";
import BaseFilterSearch from "@v4/gatsby-theme-talend/src/components/filters/FilterSearch";
import classnames from "classnames/bind";
import * as dropdownFilterStyles from "./searchFilter.module.css";

// cx for CSS Modules
const cx = classnames.bind(dropdownFilterStyles);

const FilterSearch = (props) => <BaseFilterSearch cx={cx} {...props} />;

export default FilterSearch;
